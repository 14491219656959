
/***********************************
Columns

http://geniuscarrier.com/2-columns-layout-with-flexbox/


//Band, Grid, Strip


***********************************/


.Columns {

    background-color:aquamarine;
    width:100%;
    height:auto;
    padding:0 0 0 0;
    margin:0 0 0 0;
    position:relative;
    float:left;
    overflow:hidden;

}



    /*****************************
    Band
    *****************************/

    .Columns .Band {

        background-color:transparent;
        /*max-width:1366px;*/
        width: 100%;
        height:auto;
        margin:auto;

        display:flex;
        align-items: left;


    }

        .Columns .BandBox {

            background-color:#454d5f;

            width:100%;        
            height:auto;
            max-height:90px;

            flex-grow:1;
            margin:0 20px 0 20px;

            position:relative;
        

        }
        .Columns .BandBox:nth-of-type(odd){ 
            background: #85929E; 
            color: white; 
        } 
        .Columns .BandBox:nth-of-type(even){ 
            background: #A5DDEF; 
            color: green; 
        } 
        






        .Columns .PageBandBox {

            background-color:#454d5f;

            width:100%;        
            height:auto;
            max-height:90px;

            flex-grow:1;
            margin:0 20px 0 20px;

            position:relative;
        

        }
        .Columns .PageBandBox:nth-of-type(odd){ 
            background: lightsalmon; 
            color: white; 
        } 
        .Columns .PageBandBox:nth-of-type(even){ 
            background: lightblue; 
            color: green; 
        } 
        







        /*

        padding-top: 100%       1:1 Aspect Ratio 
        padding-top: 56.25%;    16:9 Aspect Ratio (divide 9 by 16 = 0.5625) 
        padding-top: 75%;       4:3 Aspect Ratio (divide 3 by 4 = 0.75) 
        padding-top: 66.66%;    3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  
        padding-top: 62.5%;     8:5 Aspect Ratio (divide 5 by 8 = 0.625) 

        */


        .Columns .Thmb {

            background-color:transparent;
            border:1px solid transparent;
            width:100%;
            height:auto;
            margin:0 0 0 0;

            overflow:hidden;

        }
        @media screen and (max-width: 800px) {

            .Columns .Thmb {

                background-color:transparent;
    
                width: 100%;  
                margin:0 0 0 0;

            }

        }
        

            .Columns .Thmb .Img{

                width:100%;
                height:auto;
                margin:0 0 15px 0;

            }




        .Columns .Heading {

            width:100%;
            height:auto;
            margin:15px 0 15px 0;

            font-size: 30px;
            font-weight: normal;
            text-align: left;
            text-decoration:none;
            color:gray;
            line-height: 1.0;

        }




        .Columns .Mask {

            background-color:transparent;
            width:100%;
            height:100%;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:absolute;
            top:0px;
            left:0px;
            z-index:2;

        }
        .Columns .Mask .MaskBox {
            transition: .5s ease;
            opacity: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
        }

        .Columns .BandBox:hover .MaskBox {

            opacity: 1;

        }  
        .Columns .PageBandBox:hover .MaskBox {

            opacity: 1;

        }  

        .Columns .Mask .MaskTxt {

            background-color: #4CAF50;
            color: white;
            font-size: 16px;
            padding: 16px 32px;

        }





