
/****************************************************************************
*****************************************************************************

https://stackoverflow.com/questions/12251750/can-media-queries-resize-based-on-a-div-element-instead-of-the-screen


img {
  -webkit-filter: grayscale(100%); / Safari 6.0 - 9.0 
  filter: grayscale(100%);
}


/*
https://www.w3schools.com/howto/howto_css_image_overlay.asp
*/


/* https://www.w3schools.com/css/css3_object-fit.asp */

/* .fill {object-fit: fill;}
.contain {object-fit: contain;}
.cover {object-fit: cover;}
.scale-down {object-fit: scale-down;}
.none {object-fit: none;} */

/****************************************************************************
*****************************************************************************/



.Img {

    width:100%;
    height:100%;
    object-fit: contain;


}
