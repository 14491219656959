
.Checklist{

    background-color:lightyellow;
    border:1px solid black;
    border-radius:2px;

    width:calc(100% - 22px);
    height:auto;

    padding:0 10px 10px 10px;
    margin:0 0 15px 0;


    position:relative;
    float:left;



}

    .Checklist .Title {

        background-color:transparent;

        width:calc(100% - 0px);
        height:auto;
        padding:0 0 0 0;
        margin:0 0 15px 0;
        color:black;

        font-family:Arial, Helvetica, sans-serif;
        font-size: 24px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:black;

    }


    .Checklist .Item {

        background-color:transparent;

        width:calc(100% - 0px);
        height:auto;
        padding:0 0 0 0;
        margin:0 0 10px 0px;

        position:relative;
        float:left;

        display:flex;
        align-items: left;
    
    }

    .Checklist .Item .Left {

        background-color:transparent;
        height:auto;
        min-height:20px;

    }
    .Checklist .Item .Right {

        background-color:transparent;
        height:auto;

        flex-grow:1;

    }
    .Checklist .Item .Bullet {

        background-color:transparent;
        width:auto;
        height:auto;
        padding:0 15px 0 15px;
        margin:0 0 0 0;

        position:relative;
        float:left;

    }


        .Checklist .Item .Bullet .Circle {

            background-color:#0291cd;
            border-radius:100%;
    
            width:13px;
            height:13px;
            padding:0 0 0 0;
            margin:4px 0 0 10px;
            position:relative;
            float:left;
            cursor:pointer;
        }
    



    .Checklist .Item .Number {

        background-color:transparent;
        height:auto;
        padding:0 15px 0 15px;
        margin:0 0 0 0;

        position:relative;
        float:left;


        font-family:Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration:none;
        color:black;

        word-wrap: break-word;

    }


    .Checklist .Item .Value {

        background-color:transparent;
        width:90%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;

        position:relative;
        float:left;
    

        font-family:Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:black;


        word-wrap: break-word;

    }






