.FilmStrip {


    background-color:white;

    width   :100%;
    height  :auto;

    padding :0 0 0 0; 
    margin  :auto;

    position:relative;   
    


}



.FilmStrip .Grid {

    background-color:transparent;

    width   :100%;
    height  :auto;

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:relative;   
    float:left;

}


.FilmStrip .Column {

    background-color:transparent;

    width   :50%;
    height  :auto;

    padding :0 0 50px 0; 
    margin  :0 0 0 0;

    position:relative;   
    float:left;

    display : block;

    overflow:hidden;

    /*border:1px solid white;*/


}





.FilmStrip .Cell {

    background-color:white;

    width   :100%;
    /*height  :auto;*/
    height:100vh;

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:relative;   
    float:left;

    display : block;

    overflow:hidden;

    /*border:1px solid lightgray;*/


    cursor:pointer;
}





    .FilmStrip .Cell .Thmb {

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;/*vertical horizontal center*/
        overflow: hidden;

        background-color:#454d5f;
        height:64vh;


    }
    @media (max-width: 1050px) {

        .FilmStrip .Cell .Thmb {

            height:64vh;

        }

    }
    @media (max-width: 800px) {

        .FilmStrip .Cell .Thmb {

            height:64vh;

        }

    }
    @media (max-width: 500px) {

        .FilmStrip .Cell .Thmb {

            height:64vh;

        }

    }


        .FilmStrip .Cell .Thmb img {

            flex-shrink: 0;

            min-width: 100%;
            /*min-height: 64%;*/

            position:relative;
            float:left;

            margin:auto;


        }











.FilmStrip .Thumb {

    background-color:black;

    width   :98%;
    height  :55%;

    padding :0 0 0 0; 
    margin  :0 0 0 1%;

    position:relative;   
    float:left;

    display : block;

    overflow:hidden;
}

















.FilmStrip .Title {

    padding:0 0 4px 0;
    margin:20px 0 10px 0;

    color: #454D5F;
    font-size:24px;
    font-weight:bold;   
    text-align: center;

}
@media (min-width: 1024px) {

    .FilmStrip .Title {

        font-size:1.5em;

    }
    
}


.FilmStrip .Text {

    margin:0 0 20px 0;
    padding:10px 15px 20px 15px;
    color: #000;
    font-size:16px;



}
@media (min-width: 1024px) {

    .FilmStrip .Text {

        font-size: 1em;

    }

}

