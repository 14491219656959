

/*********************************

*********************************/


.PriceForm {

    background-color:transparent;
    width:100%;
	height:100%;
	
    align-items: center;
	flex-direction: column;

	position:relative;
	float:left;
	
}



.PriceForm .logo {
    margin-bottom: 12px;
    height: 90px;
    font-weight: bold;
}


.PriceForm .header {


    /*horizontal-align: center;*/

    background-color:transparent;  

    width:98%;
    height:auto;
    margin: 7px 0 7px 0;
    padding:7px 0 7px 0;

    font-size: 25px;
    font-weight: 500;
    text-align:left;
	color:#00FF00;
	
 
}



.PriceForm .underlined {


    /*horizontal-align: center;*/

    background-color:transparent;  

    width:100%;
    height:auto;
    margin: 7px 0 15px 0;
    padding:0 0 4px 0;

    font-size: 25px;
    font-weight: 500;
    text-align:left;
	color:#1A1926;
	
	border-bottom:1px solid #1A1926;

 
}




.PriceForm .spacer {


    background-color:transparent;
    width:100%;
    height:20px;
	margin:5px 0 5px 0;
	padding:0 0 0 0;

    position:relative;
	float:left;
	


}




.PriceForm .separator {

    /*horizontal-align: center;*/

    background-color:#454D5F;  
    width:100%;
    height:1px;
    margin: 30px 0 33px 0;
    padding:0 0 0 0;


}







/*************************************
//icon-button
*************************************/

.PriceForm .icon-button {
  
   
    background-color:#07a2e3; 

    width:100%;
    height:auto;
    margin:60px 0 15px 0;
    padding:0 0 0 0;

    position:relative;
    float:left;

    font-size: 18px;
    font-weight:500;
    text-align:center;
    color:white;

    border-radius:7px;

    

}


.PriceForm .icon-button:disabled {
    opacity: 0.5;
}




	.PriceForm .icon-button .mask{

		background-color:transparent;

		width:100%;
		height:100%;
		margin: 0 0 0 0;
		padding:0 0 0 0;
	
		position:absolute;
		top:0px;
		left:0px;
		z-index:2;
	
		cursor:pointer;


	}


	.PriceForm .icon-button .spr{

		background-color:transparent;
		background-image:url(../Graphics/calculator.png);
		background-repeat:no-repeat;
		background-position:-150px 0px;

		width:32px;
		height:45px;

		padding:0 0 0 0;
		margin:8px 0 0 10px;
		float:left;
		
		cursor:pointer;

	}

	.PriceForm .icon-button .txt{

		
		background-color:transparent;

		width:auto;
		height:auto;

		padding:12px 0 12px 0;
		margin:0 15px 0 0;
		float:right;
		
		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:30px;
		font-weight:bold;
		color:#1E232D;
		text-align:right;


		cursor:pointer;

	}






.PriceForm .headline {

	/*horizontal-align: center;*/


	/* #F9F9F9  #EEF3F9   #EDEEF1  #EBECF1*/

	background-color:#EDEEF1; 
	border:1px solid lightgray;
	border-radius:4px;
	

	width:98%;
	height:auto;
	margin: 18px 0 7px 0;
	padding:10px 0 10px 10px;


	position:relative;
	float:left;

	font-family:Arial, Helvetica, sans-serif;
	font-size: 18px;
	font-weight: 500;
	text-align:left;
	color:black;


}
	
	





/*************************************
//titlegrid
*************************************/

.PriceForm .titlegrid {

	background-color:transparent;
	width:86%;
	height:auto;
	padding:0 0 0 0;
	margin:15px 0 10px 7%;

	position:relative;
	float:left;

	/*
	display:inline-grid;
	grid-template-columns: repeat(3,auto);
	*/
	

}


	.PriceForm .titlegrid .cell {

		background-color:transparent;

		width:40%;
		height:auto;
		padding:0 0 0 0;
		margin:0 0 0 0;
		float:left;

		font-family:"Verdana", sans-serif;      
		font-size: 14px;
		font-weight: normal;
		text-align: center;
		text-decoration:none;
		color:#fff;

		/*display:block;*/


	}












/***************************************
****************************************





FFbutton





****************************************
***************************************/


.PriceForm .FFbutton {
  
   
    background-color:transparent; /*#07a2e3*/
	/* border:1px solid lightgray;
	border-radius:7px; */

    width:40%;
    height:auto;
    margin: 20px 0 0 30%;
    padding:5px 0 5px 0;

    position:relative;
    float:left;

	font-family:Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight:500;
    text-align:center;
    color:lightgray;
	text-decoration:underline;

	cursor:pointer;
    

}
.PriceForm .FFbutton:hover {
  
   
    color:#07a2e3;
	text-decoration:underline;
	cursor:pointer;
    

}










/***************************************
****************************************





FRM





****************************************
***************************************/

.PriceForm .frm {


    background-color:transparent;

    width:100%;
	height:auto;

    padding:0 0 0 0;	
	margin:0 0 0 0;
	position:relative;
    float:left;

    overflow:visible;



}








/***************************************
****************************************





CALCBY





****************************************
***************************************/


.PriceForm .frm .calcby{

	background-color:transparent;

	width:92%;
	height:auto;
	padding:0 0 0 0;
	margin:0 0 0 4%;
	float:left;
	overflow:visible;


}


	/********************************************
	label
	********************************************/

	.PriceForm .frm .calcby .label {

		background-color:transparent;

		width:40%;
		height:auto;
		padding:13px 0 13px 0;
		margin:0 0 0 0;
		float:left;
		overflow:visible;

		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:18px;
		font-weight:normal;
		color:#FFF;
		text-align:left;

	}


	/********************************************
	graphic-button
	********************************************/

	.PriceForm .frm .calcby .graphic-button {

		background-color:transparent;
		background-image:url(../Graphics/selector.png);
		background-position:-0px -50px;

		width:30px;
		height:40px;
		padding:0 0 0 0;
		margin:5px 13px 0 13px;
		float:right;
		overflow:hidden;

		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:18px;
		font-weight:normal;
		color:#191e28;
		text-align:left;

		cursor:pointer;


		/*border:2px solid #00ff00;*/

	}

    @media (max-width: 375px) {

		.PriceForm .frm .calcby .graphic-button {

			margin:5px 15px 0 0;

		}

	}











/***************************************
****************************************





CHECKBUTTON





****************************************
***************************************/


.PriceForm .frm .checkbutton{

	background-color:transparent;

	width:85%;
	height:auto;
	padding:0 15% 0 0;
	margin:0 0 0 0;
	float:left;
	overflow:visible;


}


	/********************************************
	label
	********************************************/

	.PriceForm .frm .checkbutton .label{

		background-color:transparent;

		width:40%;
		height:auto;
		padding:13px 0 13px 0;
		margin:0 0 0 0;
		float:left;
		overflow:visible;

		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:18px;
		font-weight:normal;
		color:#FFF;
		text-align:left;

	}


	/********************************************
	graphic-button
	********************************************/

	.PriceForm .frm .checkbutton .graphic-button{

		background-color:transparent;
		background-image:url(../Graphics/check_mark.png);
		background-repeat:no-repeat;
		background-position:-0px -0px;

		width:30px;
		height:30px;
		padding:0 0 0 0;
		margin:7px 0 0 0;
		float:right;
		overflow:hidden;

		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:18px;
		font-weight:normal;
		color:#191e28;
		text-align:left;

		cursor:pointer;


		border:2px solid #fff;
		border-radius: 7px;

	}
















/***************************************
****************************************





COSTFIELD





****************************************
***************************************/


.PriceForm .frm .costfield {

	background-color:transparent;

	width:92%;
	height:auto;
	padding:0 0 0 0;
	margin:0 0 7px 4%;
	float:left;
	overflow:visible;


}


	/********************************************
	label
	********************************************/

	.PriceForm .frm .costfield .label {

		background-color:transparent;

		width:25%;
		height:auto;
		padding:13px 0 13px 0;
		margin:0 0 0 0;
		float:left;
		overflow:visible;

		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:18px;
		font-weight:normal;
		color:#fff;
		text-align:left;
		text-decoration:underline;


		cursor:pointer;

	}

	.PriceForm .frm .costfield .label:hover {


		color:yellow;
		text-decoration:underline;

	}

    @media (max-width: 400px) {

		.PriceForm .frm .costfield .label {

			width:23%;

		}

	}





	.PriceForm .frm .costfield .label-wrap {

		background-color:white;

		width:25%;
		height:auto;
		padding:0 0 0 0;

		margin:0 0 0 0;
		position:relative;
		float:left;

		overflow:hidden;

		border:2px solid #07a2e3;
		border-radius:2px;

	}

    @media (max-width: 400px) {

		.PriceForm .frm .costfield .label-wrap {

			width:23%;

		}

	}




		.PriceForm .frm .costfield .label-wrap .input-tag {
		
			background-color:white;
		
			width:99%;
			height:auto;
			
			padding:12px 0 8px 5px;
			margin:0 0 0 0;

			position:relative;
			float:left;
		
			font-family:Verdana, Arial, Helvetica, sans-serif;
			font-size:18px;
			font-weight:normal;
			color:#000;
			text-align:left;
		
			border:0px solid #fff;
			border-radius:2px;
		
		
		}









	/********************************************
	color
	********************************************/

	.PriceForm .frm .costfield .colorline {

		background-color:yellow;

		width:4px;
		height:auto;
		padding:13px 0 12px 0;
		margin:1px 2px 0 2px;
		position:relative;
		float:right;
		overflow:hidden;

		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:14px;
		font-weight:normal;
		color:yellow;
		text-align:center;


	}


	/********************************************
	percent
	********************************************/

	.PriceForm .frm .costfield .by {

		background-color:transparent;

		width:auto;
		height:auto;
		padding:12px 0 8px 0;
		margin:0 10px 0 10px;
		float:right;
		overflow:visible;

		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:20px;
		font-weight:normal;
		color:#fff;
		text-align:left;

	}



	/********************************************
	rate
	********************************************/

	.PriceForm .frm .costfield .rate-wrap {

		background-color:white;

		width:30%;
		height:auto;
		padding:0 0 0 0;

		margin:0 0 0 0;
		position:relative;
		float:right;

		overflow:visible;

		border:1px solid #000;
		border-top-right-radius:7px;
		border-bottom-right-radius: 7px;

	}

    @media (max-width: 400px) {

		.PriceForm .frm .costfield .rate-wrap {

			width:28%;

		}

	}



		.PriceForm .frm .costfield .rate-wrap .symbol {
			
			background-color:transparent;
		
			width:15px;
			height:auto;
			
			padding:10px 0 8px 0;
			margin:0 0 0 0;

			position:absolute;
			top:0;
			left:2px;
			z-index:2;
		
			font-family:Verdana, Arial, Helvetica, sans-serif;
			font-size:18px;
			font-weight:normal;
			color:#000;
			text-align:center;
		
	
		}





		.PriceForm .frm .costfield .rate-wrap .input-tag {
		
			background-color:white;
		
			width:90%;
			height:auto;
			
			padding:12px 9px 8px 0;
			margin:0 0 0 0;
			float:right;
		
			font-family:Verdana, Arial, Helvetica, sans-serif;
			font-size:18px;
			font-weight:normal;
			color:#000;
			text-align:right;
		
			border:0px solid #FFF;
		
		
		}







	/********************************************
	qtyyhrs
	********************************************/


	.PriceForm .frm .costfield .qtyhrs-wrap {

		background-color:white;

		width:20%;
		height:auto;
		padding:0 0 0 0;

		margin:0 0 0 0;
		position:relative;
		float:right;

		overflow:hidden;

		border:1px solid #000;
		border-radius:7px;

	}

    @media (max-width: 400px) {

		.PriceForm .frm .costfield .qtyhrs-wrap {

			width:18%;

		}

	}




		.PriceForm .frm .costfield .qtyhrs-wrap .input-tag {
		
			background-color:white;
		
			width:85%;
			height:auto;
			
			padding:12px 9px 8px 0;
			margin:0 0 0 0;

			position:relative;
			float:right;
		
			font-family:Verdana, Arial, Helvetica, sans-serif;
			font-size:18px;
			font-weight:normal;
			color:#000;
			text-align:right;
		
			border:0px solid #FFF;
			border-radius:7px;
		
		
		}









/***************************************
****************************************





bluntfield





****************************************
***************************************/


.PriceForm .frm .bluntfield{

	background-color:transparent;

	width:92%;
	height:auto;
	padding:0 0 0 0;
	margin:0 0 0 4%;
	float:left;
	overflow:visible;


}


	/********************************************
	label
	********************************************/

	.PriceForm .frm .bluntfield .label {

		background-color:transparent;

		width:40%;
		height:auto;
		padding:13px 0 13px 0;
		margin:0 0 0 0;
		float:left;
		overflow:visible;

		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:18px;
		font-weight:normal;
		color:#FFF;
		text-align:left;

	}

    @media (max-width: 475px) {

		.PriceForm .frm .bluntfield .label {

			width:35%;

		}

	}



	/********************************************
	label
	********************************************/

	.PriceForm .frm .bluntfield .colorline{

		background-color:#00ff00;

		width:4px;
		height:auto;
		padding:13px 0 12px 0;
		margin:1px 2px 0 2px;
		position:relative;
		float:right;
		overflow:hidden;

		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:14px;
		font-weight:normal;
		color:#00FF00;
		text-align:center;


	}



	
	/********************************************
	price
	********************************************/

	.PriceForm .frm .bluntfield .large-wrap{

		background-color:white;

		width:30%;
		height:auto;
		padding:0 0 0 0;

		margin:0 0 0 0;
		position:relative;
		float:right;

		overflow:visible;

		border:1px solid #000;
		border-top-right-radius:7px;
		border-bottom-right-radius: 7px;

	}

    @media (max-width: 475px) {

		.PriceForm .frm .bluntfield .large-wrap {

			width:40%;

		}

	}





		.PriceForm .frm .bluntfield .large-wrap .input-tag{
		
			background-color:white;
		
			width:90%;
			height:auto;
			
			padding:10px 9px 10px 0;
			margin:0 0 0 0;
			float:right;
		
			font-family:Verdana, Arial, Helvetica, sans-serif;
			font-size:18px;
			font-weight:normal;
			color:#000;
			text-align:right;
		
			border:0px solid #FFF;
		
		
		}






	/********************************************
	percent
	********************************************/

	.PriceForm .frm .bluntfield .small-wrap{

		background-color:white;

		width:20%;
		height:auto;
		padding:0 0 0 0;

		margin:0 0 0 0;
		position:relative;
		float:right;

		overflow:visible;

		border:1px solid #000;
		border-top-right-radius:7px;
		border-bottom-right-radius: 7px;

	}


	@media (max-width: 475px) {

		.PriceForm .frm .bluntfield .small-wrap {

			width:30%;

		}

	}




		.PriceForm .frm .bluntfield .small-wrap .input-tag{
		
			background-color:white;
		
			width:90%;
			height:auto;
			
			padding:10px 9px 10px 0;
			margin:0 0 0 0;
			float:right;
		
			font-family:Verdana, Arial, Helvetica, sans-serif;
			font-size:18px;
			font-weight:normal;
			color:#000;
			text-align:right;
		
			border:0px solid #FFF;
		
		
		}









	/********************************************
	//symbol
	********************************************/

	.PriceForm .frm .bluntfield .symbol{

		background-color:transparent;

		width:auto;
		height:auto;
		padding:10px 0 10px 0;
		margin:0 10px 0 10px;
		float:right;
		overflow:visible;

		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:20px;
		font-weight:normal;
		color:#fff;
		text-align:left;

	}










/********************************************
controller
********************************************/

.PriceForm .frm .controller {

	background-color:transparent;

	width:30px;
	height:40px;
	padding:0 0 0 0;

	margin:2px 0 0 0;
	position:relative;
	float:right;

	overflow:hidden;

}



	.PriceForm .frm .controller .button {

		background-color:transparent;

		width:auto;
		height:auto;
		padding:10px 0 10px 0;
		margin:0 0 0 7px;
		float:left;
		overflow:visible;

		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:20px;
		font-weight:normal;
		color:#fff;
		text-align:center;

		cursor:pointer;

	}



		.PriceForm .frm .controller .button .icon {

			width:25px;
			height:25px;

		}






