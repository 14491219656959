

/*

This document controls the style for HashLinx 

- used within the KnowledgeBase for in page navigation

*/



.HashSection {

    background-color:transparent;
    border-bottom:0px solid #454d5f;
    width:100%; 
    height:auto; 
    margin:50px 0 0 0; 
    padding:15px 0 4px 0; 

    position:relative;
    float:left;

}




    .HashSection .HashTitle {

        background-color:transparent;

        width:calc(100% - 55px);
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:Arial, Helvetica, sans-serif;
        font-size: 32px;
        font-weight: 550;
        text-align: left;
        text-decoration:none;
        color:black;


        line-height:1.0;


    }



    .HashSection .HashButton {

        background-color:#edeef1;
        /* border-radius:100%; */

        width:40px;
        height:40px;
        padding:0 0 0 0;
        margin:0 0 0 0;

        position:absolute;
        /* top:calc(50% - 20px); */
        top:10px;
        right:0px;
        z-index:2;

        font-family:Arial, Helvetica, sans-serif;
        font-size:20px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:#edeef1;

    }


        .HashSection .HashButton .HashButtonClick {

            background-color:transparent;
            /* border-radius:100%; */

            width:100%;
            height:100%;
            padding:0 0 0 0;
            margin:0 0 0 0;

            position:absolute;
            top:0;
            right:0;
            z-index:3;


            font-family:Arial, Helvetica, sans-serif;
            font-size:2px;
            font-weight: normal;
            text-align: center;
            text-decoration:none;
            color:transparent;
    



        }







.HashList{

    background-color:#f9f9f9;
    border:2px solid #0291cd;
    border-radius:5px;

    width:calc(100% - 26px);
    height:auto;

    padding:0 10px 10px 10px;
    margin:0 0 15px 0;


    position:relative;
    float:left;



}

    .HashList a.ListAnchor{

        background-color:transparent;
        width:auto;
        height:auto;

        font-family:Arial, Helvetica, sans-serif;
        font-size:18px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:#0291cd;

        cursor:pointer; 

    }
    .HashList a.ListAnchor:link { color: #0291cd; text-decoration: none}
    .HashList a.ListAnchor:active { color: #0291cd; text-decoration: none}
    .HashList a.ListAnchor:visited { color: #0291cd; text-decoration: none}
    .HashList a.ListAnchor:hover { color:#20b2aa; text-decoration: underline }  





    .HashList .ListTitle {

        /* background-color:#edeef1;
        border:1px solid lightgray;
        border-radius:7px;  */

        width:calc(100%-10px);
        height:auto;
        padding:0 0 0 10px;
        margin:10px 0 15px 0;


        font-family:Arial, Helvetica, sans-serif;
        font-size:27px;
        font-weight:550;
        text-align: left;
        text-decoration:none;
        color:black;

    }


    .HashList .ListItem {

        background-color:transparent;

        width:calc(100% - 0px);
        height:auto;
        padding:0 0 0 0;
        margin:0 0 10px 0px;

        position:relative;
        float:left;

        display:flex;
        align-items: left;
    
    }

    .HashList .ListItem .Left {

        background-color:transparent;
        height:auto;
        min-height:20px;

    }
    .HashList .ListItem .Right {

        background-color:transparent;
        height:auto;

        flex-grow:1;

    }
    .HashList .ListItem .Bullet {

        background-color:transparent;
        width:auto;
        height:auto;
        padding:0 15px 0 15px;
        margin:0 0 0 0;

        position:relative;
        float:left;

    }


        .HashList .ListItem .Bullet .Circle {

            background-color:#0291cd;
            border-radius:100%;
    
            width:13px;
            height:13px;
            padding:0 0 0 0;
            margin:4px 0 0 10px;
            position:relative;
            float:left;
            cursor:pointer;
        }

        .HashList .ListItem .Bullet .Square {

            background-color:#0291cd;
        
            width:18px;
            height:18px;
            padding:0 0 0 0;
            margin:4px 0 0 10px;
            position:relative;
            float:left;
            cursor:pointer;
        }
    

    



    .HashList .ListItem .Value {

        background-color:transparent;
        width:90%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;

        position:relative;
        float:left;
    

        font-family:Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:black;


        word-wrap: break-word;

    }












.HashListDark{

    background-color:#191e28;
    border:4px solid #0291cd;
    border-radius:5px;

    width:calc(100% - 30px);
    height:auto;

    padding:0 10px 10px 10px;
    margin:0 0 15px 0;


    position:relative;
    float:left;



}


    .HashListDark a.ListAnchor{

        background-color:transparent;
        width:auto;
        height:auto;
        text-decoration:none;
        color:#0291cd;
        cursor:pointer; 
    }
    .HashListDark a.ListAnchor:link { color: #0291cd; text-decoration: none}
    .HashListDark a.ListAnchor:active { color: #0291cd; text-decoration: none}
    .HashListDark a.ListAnchor:visited { color: #0291cd; text-decoration: none}
    .HashListDark a.ListAnchor:hover { color:#20b2aa; text-decoration: underline }  




    .HashListDark .ListTitle {

        /* background-color:#edeef1;
        border:1px solid lightgray;
        border-radius:7px;  */

        width:calc(100%-0px);
        height:auto;
        padding:0 0 0 0px;
        margin:10px 0 15px 0;
        color:black;

        font-family:Arial, Helvetica, sans-serif;
        font-size:27px;
        font-weight:550;
        text-align: left;
        text-decoration:none;
        color:#07a2e3;

    }


    .HashListDark .ListItem {

        background-color:transparent;

        width:calc(100% - 0px);
        height:auto;
        padding:0 0 0 0;
        margin:0 0 10px 0px;

        position:relative;
        float:left;

        display:flex;
        align-items: left;
    
    }

    .HashListDark .ListItem .Left {

        background-color:transparent;
        height:auto;
        min-height:20px;

    }
    .HashListDark .ListItem .Right {

        background-color:transparent;
        height:auto;

        flex-grow:1;

    }
    .HashListDark .ListItem .Bullet {

        background-color:transparent;
        width:auto;
        height:auto;
        padding:0 15px 0 15px;
        margin:0 0 0 0;

        position:relative;
        float:left;

    }


        .HashListDark .ListItem .Bullet .Circle {

            background-color:#0291cd;
            border-radius:100%;
    
            width:13px;
            height:13px;
            padding:0 0 0 0;
            margin:4px 0 0 10px;
            position:relative;
            float:left;
            cursor:pointer;
        }

        .HashListDark .ListItem .Bullet .Square {

            background-color:#07a2e3;
        
            width:14px;
            height:14px;
            padding:0 0 0 0;
            margin:4px 0 0 10px;
            position:relative;
            float:left;
            cursor:pointer;
        }
    

    



    .HashListDark .ListItem .Value {

        background-color:transparent;
        width:90%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;

        position:relative;
        float:left;
    

        font-family:Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:black;


        word-wrap: break-word;

    }






















/*********************************************************************************************************
**********************************************************************************************************
**********************************************************************************************************
**********************************************************************************************************
**********************************************************************************************************
**********************************************************************************************************
**********************************************************************************************************
*********************************************************************************************************/


.HashSection .arrowbtn{
            
    
    background-color    : transparent;

    width       		: 100%;
    height      		: 100%;

    margin      		: 0 0 0 0;
    padding     		: 0 0 0 0;

    position      		: relative;
    float     			: left;

}





.HashSection .arrowbox{
            
 
    /* background-color    : purple; */
    background-color    : transparent;

    width:29px;
    height:21px;
    padding:0 0 0 0;
    margin:14px 0 0 0;
    float:left;

}


    .HashSection .arrowbox .arrow-left {
    
        width: 0; 
        height: 0; 
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent; 
        
        border-right:9px solid white; 
    
    
        float:left;
        margin: 0 0 0 0;
        padding:0 0 0 0;	
    
    }



    .HashSection .arrowbox .arrow-right {

        width: 0; 
        height: 0; 
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        
        border-left: 9px solid white;
    
    
        float:right;
        margin: 0 0 0 0;
        padding:0 0 0 0;	
    
    }
        



    .HashSection .arrowbox .arrow-down {

        width: 0; 
        height: 0; 
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        
        border-top: 9px solid white;


        float:right;
        margin: 0 0 0 0;
        padding:0 0 0 0;	

    }



    .HashSection .arrowbox .arrow-up {

        width: 0; 
        height: 0; 
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        
        border-bottom: 11px solid #454d5f;


        float:right;
        margin: 0 0 0 0;
        padding:0 0 0 0;	

    }

    



/******************************************************************
ARROW REFERENCES



.HashSection .arrowbox .arrow-up {

    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-bottom: 5px solid black;


    float:right;
    margin: 0 0 0 0;
    padding:0 0 0 0;	

}
    
.HashSection .arrowbox .arrow-down {

    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    
    border-top: 20px solid #f00;


    float:right;
    margin: 0 0 0 0;
    padding:0 0 0 0;	

}
    
.HashSection .arrowbox .arrow-right {

    width: 0; 
    height: 0; 
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    
    border-left: 25px solid white;


    float:right;
    margin: 0 0 0 0;
    padding:0 0 0 0;	

}
    
.HashSection .arrowbox .arrow-left {

    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    
    border-right:10px solid blue; 


    float:right;
    margin: 0 0 0 0;
    padding:0 0 0 0;	

}


*******************************************************************/





