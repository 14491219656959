
/****************************************************************************
*****************************************************************************


https://stackoverflow.com/questions/12251750/can-media-queries-resize-based-on-a-div-element-instead-of-the-screen



*****************************************************************************
*****************************************************************************/


.Vids {

    background-color:transparent;
    border:0;
    width:100%;
    height:auto;
    max-width:1680px;
    padding:0 0 0 0;
    margin:auto;
    position:relative;

   
    display: flex;
    flex-wrap: wrap;
    
    justify-content:left;

}

    /******************************
    CellA
    ******************************/


    .Vids .CellA {

        background-color:transparent;
        border:0;
        height:auto;
        margin:0px;
        flex: 0 1 25%;

        overflow:hidden;
        cursor:pointer;

        position:relative;

    }
    @media (max-width: 1066px) {

        .Vids .CellA {

            flex: 0 1 33.3333%;

        }


    }
    @media (max-width: 812px) {

        .Vids .CellA {

            flex: 0 1 50%;

        }

    }
    @media (max-width: 515px) {

        .Vids .CellA {

            flex: 0 1 100%;

        }

    }




    /******************************
    CellB - cell in a full row
    ******************************/

    .Vids .CellB {

        background-color:transparent;
        border:0;
        height:auto;
        margin:0px;
        flex: 0 1 33.33%;

        overflow:hidden;
        cursor:pointer;

        position:relative;

    }
    @media (max-width: 815px) {

        .Vids .CellB {

            flex: 0 1 50%;
    
        }

    }
    @media (max-width: 515px) {
    
        .Vids .CellB {

            flex: 0 1 100%;
    
        }

    }


    /******************************
    CellB2 - cell in page
    switches between 3 and 2 across
    ******************************/


    .Vids .CellB2 {

        background-color:transparent;
        border:0;
        height:auto;
        margin:0px;
        flex: 0 1 33.33%;

        overflow:hidden;
        cursor:pointer;

        position:relative;

    }
    @media (max-width: 1172px) {

        .Vids .CellB2 {

            flex: 0 1 50%;
    
        }

    }
    @media (max-width: 1024px) {

        .Vids .CellB2 {

            flex: 0 1 33.33%;
    
        }

    }
    @media (max-width: 870px) {

        .Vids .CellB2 {

            flex: 0 1 50%;
    
        }

    }
    @media (max-width: 550px) {
    
        .Vids .CellB2 {

            flex: 0 1 100%;
    
        }

    }


    



    


    /******************************
    CellC
    ******************************/

    .Vids .CellC {

        background-color:transparent;
        border:0;
        height:auto;
        margin:0px;
        flex: 0 1 100%;

        overflow:hidden;
        cursor:pointer;

        position:relative;

    }





    /******************************
    CellD
    ******************************/


    .Vids .CellD {

        background-color:transparent;
        border:0;
        height:auto;
        margin:0px;
        flex: 0 1 50%;

        overflow:hidden;
        cursor:pointer;

        position:relative;

    }
    @media (max-width: 515px) {

        .Vids .CellD {

            flex: 0 1 100%;

        }

    }


    /******************************
    CellD2
    ******************************/


    .Vids .CellD2 {

        background-color:transparent;
        border:0;
        height:auto;
        margin:0px;
        flex: 0 1 50%;

        overflow:hidden;
        cursor:pointer;

        position:relative;

    }
    @media (max-width: 515px) {

        .Vids .CellD2 {

            flex: 0 1 100%;

        }

    }




        /*

        https://www.w3schools.com/howto/howto_css_aspect_ratio.asp

        padding-top: 100%       1:1 Aspect Ratio 
        padding-top: 56.25%;    16:9 Aspect Ratio (divide 9 by 16 = 0.5625) 
        padding-top: 75%;       4:3 Aspect Ratio (divide 3 by 4 = 0.75) 
        padding-top: 66.66%;    3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  
        padding-top: 62.5%;     8:5 Aspect Ratio (divide 5 by 8 = 0.625) 

        */

        /*****************************************
        Sizer
        *****************************************/

        .Vids .Sizer {
        
            position: relative;
            width:calc(100% - 4px);
            padding-top: 57%; 
            margin:0 0 0 0;
            overflow:hidden;
            border:0px solid lightgray;

        }  
        .Vids .Sizer:hover {    border:0px solid red;  } 







        /*****************************************
        Mask
        *****************************************/


        .Vids .Mask {

            background-color:transparent;
            width:100%;
            height:100%;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:absolute;
            top:0px;
            left:0px;
            z-index:3;

        }


            .Vids .Mask .MaskBox {
                transition: .5s ease;
                opacity:1;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                text-align: center;
            }


  
            
            .Vids .Mask .MaskTxt {

                background-color: #4CAF50;
                color: white;
                font-size: 16px;
                padding: 16px 32px;

            }





            
        /*****************************************
        
        *****************************************/

        .Vids .Overlay {

            background-color:black;
            width:100%;
            height:100%;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:absolute;
            top:0px;
            left:1px;/* match thickness of ThmbSizer border px */
            z-index:2;


            /* IE 8 */
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
        
            /* IE 5-7 */
            filter: alpha(opacity=40);
        
            /* Netscape */
            -moz-opacity: 0.4;
        
            /* Safari 1.x */
            -khtml-opacity: 0.4;
        
            /* Good browsers */
            opacity: 0.4;



        }



        .Vids .Caption{
            
            background-color:transparent;
            width:99%;
            height:auto;
            padding:0 0 4px 0;
            margin:0 0 0 0;


            opacity:0;

            position:absolute;
            z-index:5;
            left: 50%;
            bottom:27%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%); /* IE 9 */


            font-family:Verdana, Geneva, Tahoma, sans-serif;
            font-size:16px;
            color:#fff;
            text-align:center;
         

        
        }
        .Vids .CellA:hover .Caption {    opacity:1;  }  
        .Vids .CellB:hover .Caption {    opacity:1;  }  
        .Vids .CellB2:hover .Caption {   opacity:1;  }  
        .Vids .CellC:hover .Caption {    opacity:1;  }  
        .Vids .CellD:hover .Caption {    opacity:1;  }  

             
             
        @media (max-width: 1366px) {

            .Vids .Caption {
    
                bottom:24%;
    
            }
    
        }
        @media (max-width: 1050px) {

            .Vids .Caption {
    
                bottom:20%;
    
            }
    
        }

        @media (max-width: 900px) {

            .Vids .Caption {
    
                bottom:15%;
    
            }
    
        }

        @media (max-width: 800px) {

            .Vids .Caption {
    
                bottom:0px;
                font-size:14px;
    
            }
    
        }

        @media (max-width: 500px) {

            .Vids .Caption {
    
                bottom:0px;
                font-size:12px;
    
            }
    
        }



        .Vids .CaptionB{
            
            background-color:transparent;
            width:99%;
            height:auto;
            padding:0 0 4px 0;
            margin:0 0 0 0;


            opacity:0;

            position:absolute;
            z-index:5;
            left: 50%;
            bottom:0px;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%); /* IE 9 */


            font-family:Verdana, Geneva, Tahoma, sans-serif;
            font-size:16px;
            color:#fff;
            text-align:center;
         

        
        }
        .Vids .CellA:hover .CaptionB {    opacity:1;  }  
        .Vids .CellB:hover .CaptionB {    opacity:1;  }  
        .Vids .CellB2:hover .CaptionB {   opacity:1;  }  
        .Vids .CellC:hover .CaptionB {    opacity:1;  }  
        .Vids .CellD:hover .CaptionB {    opacity:1;  }  






        






        .Vids .Circle{
            
            background-color:transparent;
            width:40px;
            height:40px;
            padding:0 0 0 0;
            margin:0 0 0 0;


            border-radius: 80px;
            border: 4px solid #FFF;
            cursor:pointer;

            position:absolute;
            z-index:5;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            /*
            Nope =(
            margin-left: -25%;
            margin-top: -25%;
            */
        
            /*
            Not even necessary really. 
            e.g. Height could be left out!
            
            width: 40%;
            height: 50%;

            */
            
    
        
        }
        
        
            .Vids .Circle .ArrowBox{
                
                background-color:transparent;
                width:auto;
                height:auto;
                padding:0 0 0 0;
                margin:9px 0 0 14px;

                position:relative;
                float:left;

                overflow:visible;

            }
            

                .Vids .Circle .ArrowBox .Arrow {
                        
                    width: 0; 
                    height: 0; 
                    border-top: 11px solid transparent;
                    border-bottom: 11px solid transparent;
                    
                    border-left: 18px solid #FFF;
                
                
                    float:right;
                    margin: 0 0 0 0;
                    padding:0 0 0 0;	
                
                }

                .Vids .CellA:hover .Arrow {

                    border-left: 18px solid red;
                }  
                .Vids .CellB:hover .Arrow {
                
                    border-left: 18px solid red;
                                
                }  
                .Vids .CellB2:hover .Arrow {
                
                    border-left: 18px solid red;
                            
                }  
                .Vids .CellC:hover .Arrow {
                
                    border-left: 18px solid red;
                            
                }  
                .Vids .CellD:hover .Arrow {
                
                    border-left: 18px solid red;
                            
                }  

                
               
                



                





                /*
                .Vids .arrowbox .arrow-right {
                    
                    width: 0; 
                    height: 0; 
                    border-top: 16px solid transparent;
                    border-bottom: 16px solid transparent;
                    
                    border-left: 16px solid #454D5F;
                
                
                    float:right;
                    margin: 0 0 0 0;
                    padding:0 0 0 0;	
                
                }
        

        
                
                .Vids .arrowbox .arrow-left {
                
                    width: 0; 
                    height: 0; 
                    border-top: 24px solid transparent;
                    border-bottom: 24px solid transparent; 
                    
                    border-right:24px solid #454D5F; 
                
                
                    float:left;
                    margin: 0 0 0 0;
                    padding:0 0 0 0;	
                
                }
                */
            





                
                



















        .Vids .Container {

            background-color:white;
            border:1px solid lightgray;
            border-radius:0;
            width:100%;
            height:auto;
            padding:5px 0 15px 0;
            margin:0 0 0 0;
            position:relative;
            float:left;


        }
        /*
        .Vids .CellB:hover .Container {

            border:1px solid #454d5f;
    
        }
        */




            .Vids .Container .Thumb {

                background-color:black;
                width:100%;
                height:auto;
                padding:0 0 0 0;
                margin:0 0 0 0;

                position: relative;
                float: left;
                overflow: hidden;

            }







        /************************************
        Version 1
        *************************************/


        .Vids .ThmbSizer {

            background-color:black;
            border:1px solid gray;
            position: relative;
            width: 100%;
            padding-top: 57%; 
            margin:0 0 0 0;
            overflow:hidden;


        }
        .Vids .CellA:hover .ThmbSizer {

            border-color:red;
        
        }
        .Vids .CellB:hover .ThmbSizer {
        
            border-color:red;
        
        }
        .Vids .CellB2:hover .ThmbSizer {
        
            border-color:red;
        
        }
        .Vids .CellC:hover .ThmbSizer {
        
            border-color:red;
        
        }
        .Vids .CellD:hover .ThmbSizer {
        
            border-color:red;
        
        }
        




 
        .Vids .Thmb {

            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items:center;/*vertical horizontal center*/
            overflow: hidden;
            
            position:absolute;
            top:0px;
            left:0px;
            z-index:1;

            background-color:#000;
            width:100%;
            height:100%;

        }



            .Vids .Thmb .Img {

                flex-shrink: 0;

                min-width: 100%;
                min-height: 100%;

                position:relative;
                float:left;

                margin:auto;

                opacity: 1;
                display: block;
                width: 100%;
                height: auto;
                transition: .5s ease;
                backface-visibility: hidden;

              
            }




            /*************************************

            //ImageOnly 

            **************************************/

            /*
            https://www.w3schools.com/howto/howto_css_image_overlay.asp
            */

            .Vids .ImageOnly_Img {

                flex-shrink: 0;

                min-width: 100%;
                max-width: auto;
                
                min-height: 100%;

                position:relative;
                float:left;
                overflow:hidden;

                margin:0 0 0 0;

                opacity: 1;
                display: block;
                width: 100%;
                height: auto;
                transition: .5s ease;
                backface-visibility: hidden;

            }

            .Vids .CellA:hover .ImageOnly_Img {

                opacity: 0.8;
    
            }
            .Vids .CellB:hover .ImageOnly_Img {

                opacity: 0.8;
    
            }
            .Vids .CellB2:hover .ImageOnly_Img {

                opacity: 0.8;
    
            }
            .Vids .CellC:hover .ImageOnly_Img {

                opacity: 0.8;
    
            }
            .Vids .CellD:hover .ImageOnly_Img {

                opacity: 0.8;
    
            }

    

            /*************************************

            //ImageText 

            **************************************/

            /*
            https://www.w3schools.com/howto/howto_css_image_overlay.asp
            */

            .Vids .Container .ImageText_Img {

                flex-shrink: 0;

                min-width: 100%;
                min-height: 100%;

                position:relative;
                float:left;

                margin:auto;

                opacity: 1;
                display: block;
                width: 100%;
                height: auto;
                transition: .5s ease;
                backface-visibility: hidden;


            }
            .Vids .Container:hover .ImageText_Img {

                opacity: 0.8;
    
            }




        .Vids .Heading{

            margin:0 15px 10px 15px;
            color:#454d5f;
            text-align:center;
            font-size:24px;
            font-weight:bold;

        }
        @media (min-width: 1024px) {

            .Vids .Heading{

                font-size:1.5em;
    
            }

        }
        
        
        
        .Vids .Pgraph{

            padding:0 15px 20px 15px;
            margin:0 0 0 0;
            color:#000;
            text-align:left;
            font-size:16px;

        }
        @media (min-width: 1024px) {

            .Vids .Pgraph{

                font-size:1rem;
    
            }

        }
        
        





