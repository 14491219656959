

/*********************************
graphic
*********************************/


.Readout {

    background-color:transparent;
    width:100%;
	height:100%;
	
    align-items: center;
	flex-direction: column;

	position:relative;
	float:left;
	
}



.Readout .logo {
    margin-bottom: 12px;
    height: 90px;
    font-weight: bold;
}




.Readout .heading {


    /*horizontal-align: center;*/

    background-color:transparent;  
	border-bottom: 1px solid #454d5f;

    width:calc(100% - 2px);
    height:auto;
    margin: 7px 0 30px 0;
    padding:7px 0 7px 2px;

	font-family:Arial, Helvetica, sans-serif;
	font-size: 30px;
	font-weight: 550;
	text-align:left;
	color:#fff;

	/* -webkit-text-stroke: 2px #fff;
	-webkit-text-fill-color: #00cc66;
 */

	
	
 
}




.Readout .calcHeading {


    /*horizontal-align: center;*/

    background-color:transparent;  
	border-bottom: 1px solid #454d5f;

    width:calc(100% - 2px);
    height:auto;
    margin: 7px 0 30px 0;
    padding:0 0 0 0;


}

	.Readout .calcHeading .title {


		/*horizontal-align: center;*/

		background-color:transparent;  

		width:77%;
		height:auto;
		margin: 0 0 0 0;
		padding:7px 0 7px 2px;

		font-family:Arial, Helvetica, sans-serif;
		font-size: 30px;
		font-weight: 550;
		text-align:left;
		color:#fff;
		
	
	}



@media (max-width: 500px) {

	.Readout .calcHeading .title {

		width:50%;
		
	}
}






.Readout .header {


    /*horizontal-align: center;*/

    background-color:transparent;  

    width:98%;
    height:auto;
    margin: 7px 0 25px 0;
    padding:7px 0 7px 0;

    font-size: 25px;
    font-weight: 500;
    text-align:left;
	color:#00cc66;
	
 
}



.Readout .underlined {


    /*horizontal-align: center;*/

    background-color:transparent;  

    width:100%;
    height:auto;
    margin: 7px 0 15px 0;
    padding:0 0 4px 0;

    font-size: 25px;
    font-weight: 500;
    text-align:left;
	color:#1A1926;
	
	border-bottom:1px solid #1A1926;

 
}




.Readout .spacer {


    background-color:transparent;
    width:100%;
    height:20px;
	margin:5px 0 5px 0;
	padding:0 0 0 0;

    position:relative;
	float:left;
	


}




.Readout .separator {

    /*horizontal-align: center;*/

    background-color:#454D5F;  
    width:100%;
    height:1px;
    margin: 30px 0 33px 0;
    padding:0 0 0 0;


}







/*************************************
//icon-button
*************************************/

.Readout .icon-button {
  
   
    background-color:#07a2e3; 

    width:100%;
    height:auto;
    margin:60px 0 15px 0;
    padding:0 0 0 0;

    position:relative;
    float:left;

    font-size: 18px;
    font-weight:500;
    text-align:center;
    color:white;

    border-radius:7px;

    

}


.Readout .icon-button:disabled {
    opacity: 0.5;
}




	.Readout .icon-button .mask{

		background-color:transparent;

		width:100%;
		height:100%;
		margin: 0 0 0 0;
		padding:0 0 0 0;
	
		position:absolute;
		top:0px;
		left:0px;
		z-index:2;
	
		cursor:pointer;


	}


	.Readout .icon-button .spr{

		background-color:transparent;
		background-image:url(../_Graphics/calculator.png);
		background-repeat:no-repeat;
		background-position:-150px 0px;

		width:32px;
		height:45px;

		padding:0 0 0 0;
		margin:8px 0 0 10px;
		float:left;
		
		cursor:pointer;

	}

	.Readout .icon-button .txt{

		
		background-color:transparent;

		width:auto;
		height:auto;

		padding:12px 0 12px 0;
		margin:0 15px 0 0;
		float:right;
		
		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:30px;
		font-weight:bold;
		color:#1E232D;
		text-align:right;


		cursor:pointer;

	}






.Readout .headline {

	/*horizontal-align: center;*/


	/* #F9F9F9  #EEF3F9   #EDEEF1  #EBECF1*/

	background-color:#EDEEF1; 
	border:1px solid lightgray;
	border-radius:4px;
	

	width:98%;
	height:auto;
	margin: 18px 0 7px 0;
	padding:10px 0 10px 10px;


	position:relative;
	float:left;

	font-family:Arial, Helvetica, sans-serif;
	font-size: 18px;
	font-weight: 500;
	text-align:left;
	color:black;


}
	
	





/*************************************
//titlegrid
*************************************/

.Readout .titlegrid {

	background-color:transparent;
	width:86%;
	height:auto;
	padding:0 0 0 0;
	margin:15px 0 10px 7%;

	position:relative;
	float:left;

	/*
	display:inline-grid;
	grid-template-columns: repeat(3,auto);
	*/
	

}


	.Readout .titlegrid .cell {

		background-color:transparent;

		width:40%;
		height:auto;
		padding:0 0 0 0;
		margin:0 0 0 0;
		float:left;

		font-family:"Verdana", sans-serif;      
		font-size: 14px;
		font-weight: normal;
		text-align: center;
		text-decoration:none;
		color:#fff;

		/*display:block;*/


	}












/*************************************
//btn-submit
*************************************/

.Readout .FFbutton {
  
    background-color:#454d5f; 
	border:2px solid #454d5f;
	border-radius:2px;

    width:80%;
    height:auto;
    margin: 5px 0 5px 10%;
    padding:12px 0 10px 0;

    position:relative;
    float:left;

	font-family:Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight:500;
    text-align:center;
    color:white;



    cursor:pointer;


}

.Readout .FFbutton:hover {

	background-color:transparent; 
	border:2px solid #fc0;
    color:#fc0;

}


.Readout .FFbutton:disabled {
    opacity: 0.5;
}







/*************************************
//controlPanel
*************************************/

.Readout .controlPanel {
  
    background-color:transparent; 

    width:auto;
    height:auto;
    margin: 0 0 0 0;
    padding:0 0 0 0;

    position:absolute;
    top:12px;
	right:18px;
	z-index:2;

}


	.Readout .controlPanel .backButton {
	
		background-color:#00cc66; 
		border:2px solid #00cc66;
		border-radius:50%;

		width:30px;
		height:30px;
		margin: 0 0 0 0;
		padding:0 0 0 0;

		position:relative;
		float:right;

		font-family:Arial, Helvetica, sans-serif;
		font-size: 18px;
		font-weight:550;
		text-align:center;
		color:#00cc66;

		cursor:pointer;


		-webkit-user-select: none; /* Safari */        
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE10+/Edge */
		user-select: none; /* Standard */



	}


	.Readout .controlPanel .backButton:hover {

		background-color:white; 
		border:2px solid #00cc66;
		color:#000;

	}

	.Readout .controlPanel .backButton:disabled {
		opacity: 0.5;
	}







	.Readout .controlPanel .menuButton {
	
		background-color:#fff; 
		border:2px solid #fff;

		width:auto;
		height:auto;
		margin: 0 2.5px 0 2.5px;
		padding:5px 10px 3px 10px;

		position:relative;
		float:right;

		font-family:Arial, Helvetica, sans-serif;
		font-size: 14px;
		font-weight:550;
		text-align:center;
		color:#000;

		border-radius:3px;

		cursor:pointer;


		-webkit-user-select: none; /* Safari */        
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE10+/Edge */
		user-select: none; /* Standard */



	}


	.Readout .controlPanel .menuButton:hover {

		background-color:#00cc66; 
		border:2px solid #00cc66;
		color:#000;

	}

	.Readout .controlPanel .menuButton:disabled {
		opacity: 0.5;
	}







	/* .Readout .controlPanel .arrowButton {
	
		background-color:#454d5f; 
	
		width:40px;
		height:40px;
		margin: 0 0 0 0;
		padding:0 0 0 0;

		position:relative;
		float:right;
	

		cursor:pointer;



	}

		.Readout .controlPanel .arrowButton .container {

			background-color:transparent;
			width:100%;
			height:100%;

			display: inline-block;
			vertical-align: middle;
		}
		
		.Readout .controlPanel .arrowButton .arrow {

			background-color:transparent;
			width:100%;
			height:100%;

			display: inline-block;
			border-radius: 50%;
		}
		
		.Readout .controlPanel .arrowButton .arrow:hover .left, .arrow:hover .top, .arrow:hover .bottom, .arrow:hover .right{
			border: 3px solid #00cc66;
		}
		
		.Readout .controlPanel .arrowButton .arrow:hover .left:after, .arrow:hover .top:after, .arrow:hover .bottom:after, .arrow:hover .right:after {
			border-top: 3px solid #00cc66;
			border-right: 3px solid #00cc66;
		}
		
		.Readout .controlPanel .arrowButton .left {
			display: inline-block;
			width: 70%;
			height: 70%;
			border: 3px solid #fff;
			border-radius: 50%;
			margin-right: 1.5em;
		}
		
		.Readout .controlPanel .arrowButton .left:after {
			content: '';
			display: inline-block;
			margin-top: 10px;
			margin-left: 25%;
			width: 35%;
			height: 35%;
			border-top: 3px solid #fff;
			border-right: 3px solid #fff;
			-moz-transform: rotate(-135deg);
			-webkit-transform: rotate(-135deg);
			transform: rotate(-135deg);
		}
		 */















/***************************************
****************************************





GRAPHIC TEXT





****************************************
***************************************/


.Readout .graphictext {

	background-color:transparent;

	width:100%;
	height:auto;
	min-height:175px;

	padding:0 0 0 0;
	margin:0 0 7px 0;
	float:left;
	overflow:visible;


}




	/********************************************
	graphic
	********************************************/

	.Readout .graphictext .graphic {

		background-color:transparent;

		width:40%;
		height:auto;
		min-height:175px;

		padding:0 0 0 5%;
		margin:0 0 0 0;
		position:relative;
		float:left;

		overflow:visible;

		font-family:Verdana, Arial, Helvetica, sans-serif;
		font-size:18px;
		font-weight:normal;
		color:#fff;
		text-align:right;



	}


		.Readout .graphictext .graphic .svg{

			background-color:transparent;
			width:175px;
			height:175px;
			padding:0 0 0 0;
			margin:0 auto 0 auto;
			overflow:visible;

		}




    @media (max-width: 460px) {

		.Readout .graphictext .graphic {

			width:43%;
			padding:8px 0 0 0;
			min-height:165px;

		}

			.Readout .graphictext .graphic .svg{

				background-color:transparent;
				width:165px;
				height:165px;
				padding:0 0 0 0;
				margin:0 auto 0 auto;
				overflow:visible;

			}


	}


    @media (max-width: 420px) {

		.Readout .graphictext .graphic {

			width:44%;
			padding:12px 0 0 1%;
			min-height:150px;

		}

			.Readout .graphictext .graphic .svg{

				background-color:transparent;
				width:150px;
				height:150px;
				padding:0 0 0 0;
				margin:0 auto 0 auto;
				overflow:visible;

			}


	}




	@media (max-width: 375px) {

		.Readout .graphictext .graphic {

			width:44%;
			padding:12px 0 0 1%;
			min-height:150px;

		}

			.Readout .graphictext .graphic .svg{

				background-color:transparent;
				width:150px;
				height:150px;
				padding:0 0 0 0;
				margin:0 auto 0 auto;
				overflow:visible;

			}


	}





	/********************************************
	text
	********************************************/


	.Readout .graphictext .text {

		background-color:transparent;

		width:45%;
		height:auto;
	

		padding:0 0 0 0;
		margin:0 10% 0 0;
		position:relative;
		float:right;

		overflow:hidden;


	}


		.Readout .graphictext .text .line {

			background-color:transparent;

			width:100%;
			height:auto;
	
			padding:0 0 0 0;
			margin:0 0 10px 0;
			position:relative;
			float:right;

			overflow:hidden;

			font-family:Verdana, Arial, Helvetica, sans-serif;
			font-size:40px;
			font-weight:normal;
			color:#00cc66;
			text-align:center;


		}





	@media (max-width: 460px) {


		.Readout .graphictext .text {

			width:50%;
			margin:8px 7% 0 0;

		}

			.Readout .graphictext .text .line {

				font-size:35px;

			}


	}



    @media (max-width: 420px) {

		.Readout .graphictext .text {

			width:45%;
			margin:12px 10% 0 0;

		}

			.Readout .graphictext .text .line {

				font-size:32px;

			}


	}




	@media (max-width: 375px) {


		.Readout .graphictext .text {

			width:52%;
			margin:12px 3% 0 0;

		}

			.Readout .graphictext .text .line {

				font-size:30px;

			}


	}







/***************************************
****************************************





GRAPHIC LINE TEXT





****************************************
***************************************/


.Readout .graphiclinetext {

	background-color:transparent;

	border-top:1px solid #454d5f;
	border-bottom:1px solid #454d5f;

	width:92%;
	height:auto;
	min-height:75px;

	padding:15px 0 15px 0;
	margin:0 0 7px 4%;
	float:left;
	overflow:visible;


}




	/********************************************
	graphic
	********************************************/

	.Readout .graphiclinetext .graphic {

		background-color:transparent;

		width:75px;
		height:auto;
		min-height:75px;

		padding:0 0 0 0;
		margin:0 0 0 0;
		position:relative;
		float:left;

		overflow:visible;

	}


		.Readout .graphiclinetext .graphic .svg{

			background-color:transparent;
			width:75px;
			height:75px;
			padding:0 0 0 0;
			margin:0 auto 0 auto;
			overflow:visible;

		}




    @media (max-width: 460px) {

		.Readout .graphiclinetext .graphic {

			width:75px;
			padding:8px 0 0 0;
			min-height:65px;

		}

			.Readout .graphiclinetext .graphic .svg{

				background-color:transparent;
				width:65px;
				height:65px;
				padding:0 0 0 0;
				margin:0 auto 0 auto;
				overflow:visible;

			}


	}







	/********************************************
	text
	********************************************/


	.Readout .graphiclinetext .text {

		background-color:transparent;

		width:calc(100% - 85px);
		height:auto;

		padding:0 0 0 0;
		margin:3px 0 0 0;
		position:relative;
		float:right;

	
		overflow:hidden;


	}


		.Readout .graphiclinetext .text .lineName {

			background-color:transparent;

			width:auto;
			height:auto;
	
			padding:0 0 0 0;
			margin:0 0 0 0;
			position:relative;
			float:left;

			overflow:hidden;

			font-family:Arial, Helvetica, sans-serif;
			font-size:27px;
			font-weight:normal;
			color:#00cc66;
			text-align:left;


		}

		.Readout .graphiclinetext .text .lineValue {

			background-color:transparent;

			width:auto;
			height:auto;
	
			padding:0 0 0 0;
			margin:0 0 0 0;
			position:relative;
			float:right;

			overflow:hidden;

			font-family:Arial, Helvetica, sans-serif;
			font-size:27px;
			font-weight:normal;
			color:#00cc66;
			text-align:right;


		}








	@media (max-width: 460px) {


		.Readout .graphiclinetext .text {

			width:70%;
			margin:8px 0 0 0;

		}

			.Readout .graphiclinetext .text .line {

				font-size:20px;

			}


	}
















/***************************************
****************************************





PRICE PERCENT





****************************************
***************************************/


.Readout .pricepercent {

	background-color:transparent;
	border-bottom:1px solid lightgray;

	width:92%;
	height:auto;
	padding:7px 0 7px 0;
	margin:10px 0 10px 4%;
	float:left;
	overflow:visible;



}


	/********************************************
	label
	********************************************/

	.Readout .pricepercent .label {

		background-color:transparent;

		width:50%;
		height:auto;
		padding:0 0 0 0;
		margin:0 0 0 0;
		float:left;
		overflow:visible;

		font-family:Arial, Helvetica, sans-serif;
		font-size:22px;
		font-weight:normal;
		color:#fff;
		text-align:left;
		text-decoration:none;


		cursor:pointer;

	}

	/* .Readout .frm .pricepercent .label:hover {


		color:yellow;
		text-decoration:underline;

	} */

    @media (max-width: 460px) {

		.Readout .pricepercent .label {

			width:50%;
			font-size:18px;

		}

	}



	/********************************************
	price
	********************************************/

	.Readout .pricepercent .price {

		background-color:transparent;

		width:20%;
		height:auto;
		padding:0 0 0 0;

		margin:0 0 0 0;
		position:relative;
		float:right;

		overflow:visible;

		font-family:Arial, Helvetica, sans-serif;
		font-size:22px;
		font-weight:normal;
		color:#fff;
		text-align:right;


	}

    @media (max-width: 460px) {

		.Readout .pricepercent .price {

			width:28%;
			font-size:18px;

		}

	}





	/********************************************
	percent
	********************************************/


	.Readout .pricepercent .percent {

		background-color:transparent;

		width:20%;
		height:auto;
		padding:0 0 0 0;

		margin:0 0 0 0;
		position:relative;
		float:right;

		overflow:hidden;


		font-family:Arial, Helvetica, sans-serif;
		font-size:22px;
		font-weight:normal;
		color:#fff;
		text-align:right;




	}

    @media (max-width: 460px) {

		.Readout .pricepercent .percent {

			width:18%;
			font-size:18px;

		}

	}








/***************************************
****************************************





LEDGER PLUS





****************************************
***************************************/


.Readout .ledgerplus {

	background-color:transparent;
	border-bottom:1px solid lightgray;

	width:92%;
	height:auto;
	padding:7px 0 7px 0;
	margin:10px 0 10px 4%;
	float:left;
	overflow:visible;



}




	/********************************************
	percent
	********************************************/

	.Readout .ledgerplus .percent {

		background-color:transparent;

		border-left:2px solid white;
		border-right:2px solid white;

		border-radius:20px;

		width:80px;
		height:auto;
		padding:0 0 0 0;

		margin:0 10px 0 0;
		position:relative;
		float:left;

		overflow:hidden;


		font-family:Arial, Helvetica, sans-serif;
		font-size:22px;
		font-weight:normal;
		color:#fff;
		text-align:center;




	}

    @media (max-width: 460px) {

		.Readout .ledgerplus .percent {

			width:70px;
			font-size:18px;

		}

	}








	/********************************************
	label
	********************************************/

	.Readout .ledgerplus .name {

		background-color:transparent;

		width:calc(50% - 80px);
		height:auto;
		padding:0 0 0 0;
		margin:0 0 0 0;
		float:left;
		overflow:visible;

		font-family:Arial, Helvetica, sans-serif;
		font-size:22px;
		font-weight:normal;
		color:#fff;
		text-align:left;
		text-decoration:none;


		cursor:pointer;

	}

	/* .Readout .frm .ledgerplus .label:hover {


		color:yellow;
		text-decoration:underline;

	} */

    @media (max-width: 460px) {

		.Readout .ledgerplus .name {

			width:calc(50% - 50px);
			font-size:18px;

		}

	}



	/********************************************
	price
	********************************************/

	.Readout .ledgerplus .value {

		background-color:transparent;

		width:calc(50% - 70px);
		height:auto;
		padding:0 0 0 0;

		margin:0 0 0 0;
		position:relative;
		float:right;

		overflow:visible;

		font-family:Arial, Helvetica, sans-serif;
		font-size:22px;
		font-weight:normal;
		color:#fff;
		text-align:right;


	}

    @media (max-width: 460px) {

		.Readout .ledgerplus .value {

			width:28%;
			font-size:18px;

		}

	}












/***************************************
****************************************




LEDGER





****************************************
***************************************/


.Readout .ledger {

	background-color:transparent;

	width:92%;
	height:auto;
	padding:7px 0 7px 0;
	margin:10px 0 10px 4%;
	float:left;
	overflow:visible;

	border-bottom:1px solid lightgray;
	/* border-bottom:1px solid #00ffff; */


}


	/********************************************
	label
	********************************************/

	.Readout .ledger .name {

		background-color:transparent;

		width:calc(100% - 150px);
		height:auto;
		padding:0 0 0 0;
		margin:0 0 0 0;
		float:left;
		overflow:visible;

		font-family:Arial, Helvetica, sans-serif;
		font-size:22px;
		font-weight:normal;
		color:#fff;
		text-align:left;
		text-decoration:none;


		cursor:pointer;

	}

	/* .Readout .frm .ledger .label:hover {


		color:yellow;
		text-decoration:underline;

	} */

    @media (max-width: 460px) {

		.Readout .ledger .name {

			width:50%;
			font-size:20px;

		}

	}



	/********************************************
	price
	********************************************/

	.Readout .ledger .value {

		background-color:transparent;

		width:150px;
		height:auto;
		padding:0 0 0 0;
		margin:0 0 0 0;
		position:relative;
		float:right;

		overflow:visible;

		font-family:Arial, Helvetica, sans-serif;
		font-size:22px;
		font-weight:normal;
		color:#fff;
		text-align:right;


	}

    @media (max-width: 460px) {

		.Readout .ledger .value {

			width:39%;
			font-size:20px;

		}

	}





