

/***********************************
Rows

http://geniuscarrier.com/2-columns-layout-with-flexbox/

***********************************/

.Rows {

    background-color:transparent;
    width: 100%;
    height:auto;
    padding:0 0 0 0;
    margin:0 0 50px 0;

    position:relative;
    float:left;


}
@media (max-width: 1366px) {

    .Rows {

        background-color:transparent;
        width: 100%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 50px 0;
    
    }

}
    

    .Rows .FullRow {

        background-color:transparent;
        width:100%;
        height:auto;
        margin:0 auto 0 auto;
        position:relative;

    }




    .Rows .IndentRow {

        background-color:transparent;
        width:70%;
        height:auto;
        margin:0 auto 0 auto;
        position:relative;


    }
    @media (max-width: 1680px) {

        .Rows .IndentRow {

            width: 84%;
    
        }

    }
    @media (max-width: 1250px) {

        .Rows .IndentRow {

            width: 96%;
    
        }

    }




    .Rows .IndentRowWide {

        background-color:transparent;
        width:98%;
        height:auto;
        margin:0 auto 0 auto;
        position:relative;


    }
    @media (max-width: 800px) {

        .Rows .IndentRowWide {

            width: 96%;
    
        }

    }











