

/*********************************************************
**********************************************************
*********************************************************/

.SubmitFlyr {


    background-color:white;

    width   :100%;
    height  :auto;

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:3;
    top:0;
    left:0;


}



/*********************************************************
*********************************************************/

.SubmitFlyr-message {


    background-color:white;

    width   :94%;
    height  :auto;

    padding :0 0 4px 0; 
    margin  :0 0 0 3%;

    position:relative;  
    float:left; 

    font-family:Verdana, Geneva, sans-serif;
    font-size:16px;
    font-weight:normal;
    color:#000;
    text-align:center;

  
}





/*************************************
//button
*************************************/

.SubmitFlyr-button {
  
   
    background-color:#1a1926; 

    width:100%;
    height:auto;
    margin: 12px 0 12px 0;
    padding:8px 0 7px 0;

    position:relative;
    float:left;

    font-size: 14px;
    font-weight:500;
    text-align:center;
    color:white;

    border-radius:7px;

    cursor:pointer;


}










/*********************************************************
*********************************************************/

.SubmitFlyr-printHtml {

    background-color:white;

    width   :98%;
    height  :auto;

    padding :0 0 4px 0; 
    margin  :0 0 0 1%;

    position:relative;  
    float:left; 

    font-family:Verdana, Geneva, sans-serif;
    font-size:16px;
    font-weight:normal;
    color:#000;
    text-align:left;

}



    .SubmitFlyr-printHtml .controller {
    
        background-color:#fc0; 

        width:100%;
        height:auto;
        margin:0 0 0 0;
        padding:0 0 0 0;

        position:relative;
        float:left;


    }


        .SubmitFlyr-printHtml .controller .button {
        
        
            background-color:transparent; 

            width:100%;
            height:auto;
            margin: 12px 10px 12px 10px;
            padding:8px 0 7px 0;

            position:relative;
            float:left;

            font-family:Verdana, Geneva, sans-serif;
            font-size: 18px;
            font-weight:500;
            text-align:center;
            color:black;

            cursor:pointer;


        }



    .SubmitFlyr-printHtml .pretag {

        background-color:transparent; 

        width:90%;
        height:auto;
        margin:0 0 0 5%;
        padding:0 0 0 0;

        position:relative;
        float:left;

    }





/*********************************************************
*********************************************************/

.SubmitFlyr-printout {


    background-color:white;

    width   :94%;
    height  :auto;

    padding :0 0 4px 0; 
    margin  :0 0 0 3%;

    position:relative;  
    float:left; 

    font-family:Verdana, Geneva, sans-serif;
    font-size:16px;
    font-weight:normal;
    color:#000;
    text-align:center;

  
}



    .SubmitFlyr-printout-name {


        background-color:white;

        width   :94%;
        height  :auto;

        padding :0 0 4px 0; 
        margin  :0 0 0 3%;

        position:relative;  
        float:left; 

        font-family:Verdana, Geneva, sans-serif;
        font-size:16px;
        font-weight:normal;
        color:#000;
        text-align:center;

    
    }

    .SubmitFlyr-printout-value {


        background-color:white;

        width   :94%;
        height  :auto;

        padding :0 0 4px 0; 
        margin  :0 0 0 3%;

        position:relative;  
        float:left; 

        font-family:Verdana, Geneva, sans-serif;
        font-size:16px;
        font-weight:normal;
        color:#000;
        text-align:center;

    
    }




















