
/****************************************************************************
*****************************************************************************


https://stackoverflow.com/questions/12251750/can-media-queries-resize-based-on-a-div-element-instead-of-the-screen





img {
  -webkit-filter: grayscale(100%); / Safari 6.0 - 9.0 
  filter: grayscale(100%);
}




*****************************************************************************
*****************************************************************************/


.FixedGrid {

    background-color:transparent;
    border:0;
    width:100%;
    height:auto;
    max-width:1680px;
    padding:0 0 0 0;
    margin:auto;
    position:relative;

   
    display: flex;
    flex-wrap: wrap;
    
    justify-content:left;

}



    /******************************
    CellA
    ******************************/


    .FixedGrid .CellA {

        background-color:transparent;
        border:0;
        height:auto;
        margin:0px;
        flex: 0 1 25%;

        overflow:hidden;
        cursor:pointer;

        position:relative;

    }
    @media (max-width: 1066px) {

        .FixedGrid .CellA {

            flex: 0 1 33.3333%;

        }


    }
    @media (max-width: 812px) {

        .FixedGrid .CellA {

            flex: 0 1 50%;

        }

    }
    @media (max-width: 515px) {

        .FixedGrid .CellA {

            flex: 0 1 100%;

        }

    }




    /******************************
    CellB - cell in a full row
    ******************************/

    .FixedGrid .CellB {

        background-color:transparent;
        border:0;
        height:auto;
        margin:0px;
        flex: 0 1 33.33%;

        overflow:hidden;
        cursor:pointer;

        position:relative;

    }
    @media (max-width: 815px) {

        .FixedGrid .CellB {

            flex: 0 1 50%;
    
        }

    }
    @media (max-width: 515px) {
    
        .FixedGrid .CellB {

            flex: 0 1 100%;
    
        }

    }


    /******************************
    CellB2 - cell in page
    switches between 3 and 2 across
    ******************************/


    .FixedGrid .CellB2 {

        background-color:transparent;
        border:0;
        height:auto;
        margin:0px;
        flex: 0 1 33.33%;

        overflow:hidden;
        cursor:pointer;

        position:relative;

    }
    @media (max-width: 1172px) {

        .FixedGrid .CellB2 {

            flex: 0 1 50%;
    
        }

    }
    @media (max-width: 1024px) {

        .FixedGrid .CellB2 {

            flex: 0 1 33.33%;
    
        }

    }
    @media (max-width: 870px) {

        .FixedGrid .CellB2 {

            flex: 0 1 50%;
    
        }

    }
    @media (max-width: 550px) {
    
        .FixedGrid .CellB2 {

            flex: 0 1 100%;
    
        }

    }


    



    


    /******************************
    CellC
    ******************************/

    .FixedGrid .CellC {

        background-color:transparent;
        border:0;
        height:auto;
        margin:0px;
        flex: 0 1 100%;

        overflow:hidden;
        cursor:pointer;

        position:relative;

    }





    /******************************
    CellD
    ******************************/


    .FixedGrid .CellD {

        background-color:transparent;
        border:0;
        height:auto;
        margin:0px;
        flex: 0 1 50%;

        overflow:hidden;
        cursor:pointer;

        position:relative;

    }
    @media (max-width: 515px) {

        .FixedGrid .CellD {

            flex: 0 1 100%;

        }

    }


    /******************************
    CellD2
    ******************************/


    .FixedGrid .CellD2 {

        background-color:transparent;
        border:0;
        height:auto;
        margin:0px;
        flex: 0 1 50%;

        overflow:hidden;
        cursor:pointer;

        position:relative;

    }
    @media (max-width: 515px) {

        .FixedGrid .CellD2 {

            flex: 0 1 100%;

        }

    }


        /*

        https://www.w3schools.com/howto/howto_css_aspect_ratio.asp

        padding-top: 100%       1:1 Aspect Ratio 
        padding-top: 56.25%;    16:9 Aspect Ratio (divide 9 by 16 = 0.5625) 
        padding-top: 75%;       4:3 Aspect Ratio (divide 3 by 4 = 0.75) 
        padding-top: 66.66%;    3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  
        padding-top: 62.5%;     8:5 Aspect Ratio (divide 5 by 8 = 0.625) 

        */

        /*****************************************
        Sizer
        *****************************************/

        .FixedGrid .Sizer {
        
            position: relative;
            width: 100%;
            padding-top: 64%; 
            margin:0 0 0 0;
            overflow:hidden;
            border:0;

        }
        /*
        .FixedGrid .Sizer:hover {    border:1px solid red;  } 
        */




        .FixedGrid .Mask {

            background-color:transparent;
            width:100%;
            height:100%;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:absolute;
            top:0px;
            left:0px;
            z-index:2;

        }
        .FixedGrid .Mask .MaskBox {
            transition: .5s ease;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
        }


        .FixedGrid .CellA:hover .MaskBox {

            opacity: 1;

        }  
        .FixedGrid .CellB:hover .MaskBox {

            opacity: 1;

        }  
        .FixedGrid .CellB2:hover .MaskBox {

            opacity: 1;

        }  
        .FixedGrid .CellC:hover .MaskBox {

            opacity: 1;

        }  
        .FixedGrid .CellD:hover .MaskBox {

            opacity: 1;

        }  

        
        .FixedGrid .Mask .MaskTxt {

            background-color: #4CAF50;
            color: white;
            font-size: 16px;
            padding: 16px 32px;

        }





        .FixedGrid .Container {

            background-color:white;
            border:1px solid white;
            border-radius:0;
            width:100%;
            height:auto;
            padding:5px 0 15px 0;
            margin:0 0 0 0;
            position:relative;
            float:left;
            overflow:hidden;


        }



        /************************************
        Version 1
        *************************************/


        .FixedGrid .ThmbSizer {

            background-color:black;
            border:1px solid lightgray;
            position: relative;
            width: 100%;
            padding-top: 64%; 
            margin:0 0 0 0;
            overflow:hidden;


        }


        .FixedGrid .Thmb {

            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;/*vertical horizontal center*/
            overflow: hidden;

            position:absolute;
            top:0px;
            left:0px;
            z-index:1;

            background-color:#000;
            width:100%;
            height:100%;
    

        }



            .FixedGrid .Thmb .Img {

                flex-shrink: 0;

                min-width: 100%;
                min-height: 64%;

                position:relative;
                float:left;

                margin:auto;

                opacity: 1;
                display: block;
                width: 100%;
                height: auto;
                transition: .5s ease;
                backface-visibility: hidden;


            }





            /*************************************

            //ImageOnlyStatic 

            **************************************/

            /*
            https://www.w3schools.com/howto/howto_css_image_overlay.asp
            */

            .FixedGrid .ImageOnly_StaticImg {

                flex-shrink: 0;

                min-width: 100%;
                max-width:auto;
  
                min-height: 100%;
         

                position:relative;
                float:left;

                margin:0 0 0 0;

                opacity: 1;
                display: block;
                width: 100%;
                height: auto;
                transition: .5s ease;
                backface-visibility: hidden;


            }



            @keyframes ImageOnly_FadeIn {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
            .FixedGrid .ImageOnly_FadeImg {

                flex-shrink: 0;

                min-width: 100%;
                max-width:auto;
  
                min-height: 100%;
         

                position:relative;
                float:left;

                margin:0 0 0 0;

                opacity:1;
                /* transition: opacity .5s ease; */
                /* animation:ImageOnly_FadeIn .5s infinite; */
                
                display: block;
                width: 100%;
                height: auto;
             

                backface-visibility: hidden;


            }








            /*************************************

            //ImageOnly 

            **************************************/

            /*
            https://www.w3schools.com/howto/howto_css_image_overlay.asp
            */

            .FixedGrid .ImageOnly_Img {

                flex-shrink: 0;

                min-width: 100%;
                max-width:auto;
  
                min-height: 100%;
         

                position:relative;
                float:left;

                margin:0 0 0 0;

                opacity: 1;
                display: block;
                width: 100%;
                height: auto;
                transition: .5s ease;
                backface-visibility: hidden;


            }

            .FixedGrid .CellA:hover .ImageOnly_Img {

                opacity: 0.3;
    
            }
            .FixedGrid .CellB:hover .ImageOnly_Img {

                opacity: 0.3;
    
            }
            .FixedGrid .CellB2:hover .ImageOnly_Img {

                opacity: 0.3;
    
            }
            .FixedGrid .CellC:hover .ImageOnly_Img {

                opacity: 0.3;
    
            }
            .FixedGrid .CellD:hover .ImageOnly_Img {

                opacity: 0.3;
    
            }

    

            /*************************************

            //ImageText 

            **************************************/

            /*
            https://www.w3schools.com/howto/howto_css_image_overlay.asp
            */

            .FixedGrid .Container .ImageText_Img {

                flex-shrink: 0;

                min-width: 100%;
                max-width: auto;

                min-height: 100%;

                position:relative;
                float:left;

                margin:auto;

                opacity: 1;
                display: block;
                width: 100%;
                height: auto;
                transition: .5s ease;
                backface-visibility: hidden;


            }
            .FixedGrid .Container:hover .ImageText_Img {

                opacity: 0.3;
    
            }




        .FixedGrid .Heading{

            margin:10px 0 10px 0;
            color:#454d5f;
            text-align:center;
            font-size:24px;
            font-weight:bold;

        }
        @media (min-width: 1024px) {

            .FixedGrid .Heading{

                font-size:1.5em;
    
            }

        }
        
        
        
        .FixedGrid .Pgraph{

            padding:0 15px 20px 15px;
            margin:0 0 0 0;
            color:#000;
            text-align:left;
            font-size:16px;

        }
        @media (min-width: 1024px) {

            .FixedGrid .Pgraph{

                font-size:1rem;
    
            }

        }
        
        





