/***********************************
SingleColumn
***********************************/

.SingleColumn {

    background-color:transparent;

    width: 100%;
    height: auto;

    margin:0 0 0 0;

    display:flex;
    flex-wrap: wrap;
    justify-content: center;


}

    .SingleColumn .Box {

        background-color:transparent;

        flex: 1 1 100%;
        margin: 0 0 15px 0;

    }


        .SingleColumn .Box .Txt {

            background-color:transparent;
            /*border:1px solid #454d5f;
            border-radius: 7px;*/

            width: 100%;
            height:auto;
            min-height:10px;

            font-family:Arial, Helvetica, sans-serif;
            font-size: 16px;
            font-weight: normal;
            text-align: left;
            text-decoration:none;
            color:black;
            line-height: 1.4;
        
        }










/***********************************
FluidColumns

http://geniuscarrier.com/2-columns-layout-with-flexbox/

***********************************/


.FluidColumns {

    background-color:transparent;
    width:100%;
    height:auto;
    margin:0 0 0 0;
    position:relative;
    float:left;

    font-family:Arial, Helvetica, sans-serif;



}




    /*****************************
    Single
    *****************************/


    .FluidColumns .Single {

        background-color:transparent;
        max-width:900px;
        min-height:10px;
        width: 750px;
        height:auto;
        margin:auto;

        display:flex;
        align-items:center;


    }
    @media (max-width: 900px) {

        .FluidColumns .Single {

            width: 84%;
            
    
        }

    }
    @media (max-width: 600px) {

        .FluidColumns .Single {

            width: 90%;
            
    
        }

    }

        .FluidColumns .SingleBox {

            background-color:transparent;

            width:100%;        
            height:auto;
            min-height:10px;

            flex-grow:0;
            margin:0 20px 0 20px;

            position:relative;
        

        }
        @media screen and (max-width: 800px) {

            .FluidColumns .SingleBox {

                width:100%;
                flex-grow:0;
                margin:0 0 40px 0;

            }


        }










    /*****************************
    FULL
    *****************************/


    .FluidColumns .Full {

        background-color:transparent;
        max-width:1366px;
        min-height:10px;
        width: 90%;
        height:auto;
        margin:auto;

        display:flex;
        align-items: left;


    }
    @media (max-width: 1680px) {

        .FluidColumns .Full {

            width: 84%;
            
    
        }

    }
    @media (max-width: 1250px) {

        .FluidColumns .Full {

            width: 96%;
    
        }

    }
    @media (max-width: 800px) {

        .FluidColumns .Full {

            width: 70%;

            display:flex;
            flex-wrap: wrap;
            justify-content: center;

    
        }

    }

    @media (max-width: 650px) {

        .FluidColumns .Full {

            width: 80%;

            display:flex;
            flex-wrap: wrap;
            justify-content: center;

    
        }

    }

    @media (max-width: 550px) {

        .FluidColumns .Full {

            width: 90%;

            display:flex;
            flex-wrap: wrap;
            justify-content: center;

    
        }

    }





    /*****************************
    PAGE
    *****************************/

    .FluidColumns .Page {

        background-color:transparent;
        max-width:1366px;
        min-height:10px;
        width: 98%;
        height:auto;
        margin:auto;

        display:flex;
        align-items: left;


    }
    @media (max-width: 800px) {

        .FluidColumns .Page {

            width: 70%;

            display:flex;
            flex-wrap: wrap;
            justify-content: center;

    
        }

    }

    @media (max-width: 650px) {

        .FluidColumns .Page {

            width: 80%;

            display:flex;
            flex-wrap: wrap;
            justify-content: center;

    
        }

    }

    @media (max-width: 550px) {

        .FluidColumns .Page {

            width: 90%;

            display:flex;
            flex-wrap: wrap;
            justify-content: center;

    
        }

    }









    /* Sets all div stuff for flex wrap
        NOT IN USE


        .FluidColumns > div {
        
            margin:0;

        }
    */


        .FluidColumns .Box {

            background-color:transparent;

            width:100%;        
            height:auto;
            min-height:10px;

            flex-grow:1;
            margin:0 20px 0 20px;

            position:relative;


        }
        @media screen and (max-width: 800px) {

            .FluidColumns .Box {

                width:100%;
                flex-grow:0;
                margin:0 0 40px 0;

            }


        }

            





        /*

        https://www.w3schools.com/howto/howto_css_aspect_ratio.asp

        padding-top: 100%       1:1 Aspect Ratio 
        padding-top: 56.25%;    16:9 Aspect Ratio (divide 9 by 16 = 0.5625) 
        padding-top: 75%;       4:3 Aspect Ratio (divide 3 by 4 = 0.75) 
        padding-top: 66.66%;    3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  
        padding-top: 62.5%;     8:5 Aspect Ratio (divide 5 by 8 = 0.625) 

        */


        .FluidColumns .Thmb {

            background-color:black;
            border:1px solid gray;
            position: relative;
            width: 100%;
            padding-top: 64%; 
            margin:0 0 0 0;
            overflow:hidden;


        }

            .FluidColumns .Thmb .Holder {

                position: absolute;
                width: 100%;
                height:100%;

                top:0px;
                left:0px;

                margin:0 0 0 0;

                overflow:hidden;

            }


                .FluidColumns .Thmb .Holder .Img{

                    width:100%;
                    height:auto;
                    margin:0 0 15px 0;
                    position:relative;

                }




        .FluidColumns .Heading {

            width:100%;
            height:auto;
            margin:15px 0 15px 0;

            font-family:Arial, Helvetica, sans-serif;
            font-size:32px;
            font-weight:bold;
            text-align: left;
            text-decoration:none;
            color:#454d5f;
            line-height: 1.0;

        }

        .FluidColumns .Pgraph {

            background-color:transparent;

            width:100%;
            height:auto;
            margin:0 0 25px 0;

            font-family:Arial, Helvetica, sans-serif;
            font-size:16px;
            font-weight:normal;
            text-align: left;
            text-decoration:none;
            color:#181b21;
            line-height: 1.5;

        }

        .FluidColumns .Button {

            background-color:#0291cd;
            border:1px solid #0291cd;
            border-radius:7px;

            max-width:250px;
            width:80%;
            height:auto;
            padding:12px 0 12px 0;
            margin:30px 0 0 0;
    
            font-family:Arial, Helvetica, sans-serif;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            text-decoration:none;
            color:white;


            cursor:pointer;
    

        }
















/***********************************
TextColumns
***********************************/

/**
column must be wrapped w/ display:flex
**/


.TextColumns {

    background-color:transparent;
    width:100%;
    height:auto;
    margin:0 0 0 0;
    position:relative;
    float:left;

}



    /***********************************
    FULL
    ***********************************/

    .TextColumns .Full{

        background-color:white;
        width:100%;
        height:auto;
        min-height:10px;
        padding:0 0 0 0;
        margin:auto;

        display:flex;


    }
    @media screen and (max-width: 1280px) {

        .TextColumns .Full {

            width:90%;

        }

    }


        .TextColumns .Full .Headline {

            width:100%;
            height:auto;
            margin:0 0 25px 0;

            font-family:'Times New Roman', Times, serif;   
            font-size: 40px;
            font-weight: bold;
            text-align: left;
            text-decoration:none;
            color:#000;
            line-height: 1.4;


        }
        .TextColumns .Full .Newspaper{

            column-count: 3;
            column-gap: 40px;

            column-rule: 2px solid lightseagreen;

            margin:20px 0 50px 0;

            font-family:'Times New Roman', Times, serif;   
            font-size: 20px;
            font-weight: normal;
            text-align: left;
            text-decoration:none;
            color:#000;
            line-height: 1.4;

        }
        @media screen and (max-width: 900px) {

            .TextColumns .Full .Newspaper{

                column-count: 2;
                column-gap: 40px;
                column-rule: 2px solid lightseagreen;
            }

        }
        @media screen and (max-width: 650px) {

            .TextColumns .Full .Newspaper{

                column-count: 1;
                column-gap: 2px;
                column-rule: 0;
        
            }

        }









        .TextColumns .Full .Classic{

            column-count: 2;
            column-gap: 40px;
            column-rule: 2px solid lightseagreen;

            margin:20px 0 50px 0;


        }
        @media screen and (max-width: 600px) {

            .TextColumns .Full .Classic{

                column-count: 1;
                column-gap: 2px;
                column-rule: 0;

        
            }

        }






    /***********************************
    PAGE
    ***********************************/

    .TextColumns .Page{

        background-color:white;
        width:100%;
        height:auto;
        min-height:150px;
        padding:0 0 0 0;
        margin:auto;

        display:flex;


    }
    @media screen and (max-width: 550px) {

        .TextColumns .Page {

            width:98%;

        }

    }


        .TextColumns .Page .Headline {

            width:100%;
            height:auto;
            margin:0 0 25px 0;

            font-family:'Times New Roman', Times, serif;   
            font-size: 40px;
            font-weight: bold;
            text-align: left;
            text-decoration:none;
            color:#000;
            line-height: 1.4;


        }
        .TextColumns .Page .Newspaper{

            column-count: 3;
            column-gap: 40px;
            column-rule: 2px solid lightseagreen;

            margin:20px 0 50px 0;

            font-family:'Times New Roman', Times, serif;   
            font-size: 20px;
            font-weight: normal;
            text-align: left;
            text-decoration:none;
            color:#000;
            line-height: 1.4;

        }
        @media screen and (max-width: 1366px) {

            .TextColumns .Page .Newspaper{

                column-count: 2;
                column-gap: 40px;
                column-rule: 2px solid lightseagreen;
        
            }

        }
        @media screen and (max-width: 1180px) {

            .TextColumns .Page .Newspaper{

                column-count: 1;
                column-gap: 2px;
                column-rule: 0;
            }

        }
        @media screen and (max-width: 1025px) {

            .TextColumns .Page .Newspaper{

                column-count: 3;
                column-gap: 40px;
                column-rule: 2px solid lightseagreen;
            }

        }
        @media screen and (max-width: 875px) {

            .TextColumns .Page .Newspaper{

                column-count: 2;
                column-gap: 40px;
                column-rule: 2px solid lightseagreen;
            }

        }
        @media screen and (max-width: 800px) {

            .TextColumns .Page .Newspaper{

                column-count: 1;
                column-gap: 2px;
                column-rule: 0;
        
            }

        }





















