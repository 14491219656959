a.Anchor{

    background-color:transparent;
    width:auto;
    height:auto;
    text-decoration:none;
    color:#483d8b;
    cursor:pointer; 
}
a.Anchor:link { color: #483d8b; text-decoration: none}
a.Anchor:active { color: #483d8b; text-decoration: none}
a.Anchor:visited { color: #483d8b; text-decoration: none}
a.Anchor:hover { color:#20b2aa; text-decoration: underline }  





