
/**********************************
FlexGrid

https://www.geeksforgeeks.org/how-to-set-flexbox-having-unequal-width-of-elements-using-css/

***********************************/

.FlexGrid{ 

    display: flex; 
    flex-wrap: wrap;
    min-height: 550px; 

} 
    .FlexGrid .Column{ 

        flex: 1; 
    } 
    .FlexGrid .Column:nth-of-type(odd){ 

        background:transparent;  
    } 
    .FlexGrid .Column:nth-of-type(even){ 

        background:transparent; 
    } 




    @media (max-width: 1050px) {

        .FlexGrid .Column{ 

            flex: 2; 
        } 

    }
    @media (max-width: 800px) {


        .FlexGrid .Column{ 

            flex: 3; 
        } 

    }
    @media (max-width: 500px) {

        .FlexGrid .Column{ 

            flex: 4; 
        } 


    }


        .FlexGrid .Cell {

            background-color:transparent;
            border:0;
            width:100%;
            height:auto;
            padding:0 0 0 0;
            margin:0 0 0 0;
        
            position: relative;
            float: left;
            overflow: visible;

            cursor:pointer;

        }


        /*****************************************
        Sizer
        *****************************************/

        .FlexGrid .Sizer {
        
            position: relative;
            width: 100%;
            padding-top: 64%; 
            margin:0 0 0 0;
            overflow:hidden;
            border:0;

        }





        .FlexGrid .Cell .Mask {

            background-color:transparent;
            width:100%;
            height:100%;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:absolute;
            top:0px;
            left:0px;
            z-index:2;

        }
        .FlexGrid .Cell .Mask .MaskBox {
            transition: .5s ease;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
        }
        .FlexGrid .Cell:hover .MaskBox {

            opacity: 1;

        }     
        .FlexGrid .Cell .Mask .MaskTxt {

            background-color: #4CAF50;
            color: white;
            font-size: 16px;
            padding: 16px 32px;

        }



        .FlexGrid .Mask .Icon {
                
            background-color:transparent;
        
            width:50px;
            height:50px;
            padding:0 0 0 0;
            margin:0 0 0 0;
                
            position:absolute;
            top:-27px;
            left:-27px;
            z-index:10;
        
        
            font-family:Arial, Helvetica, sans-serif;
            font-size:12px;
            font-weight:normal;
            color:white;
            text-align:center;
                            
            font-style:normal;
            
            
            user-select: none;
            -moz-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -o-user-select: none;
                
        
        }

        .FlexGrid .Mask .Icon .MagnifyingGlass {
        
            font-size: 3.7em;
            display: inline-block;
            width: 0.37em;
            box-sizing: content-box;
            height: 0.37em;
            border: 0.1em solid #EDEEF1;
            position: relative;
            border-radius: 0.30em;
        
        }
        .FlexGrid .Mask .Icon .MagnifyingGlass:before {
        
            content: "";
            display: inline-block;
            position: absolute;
            right: -0.25em;
            bottom: -0.1em;
            border-width: 0;
            background: #EDEEF1;
            width: 0.35em;
            height: 0.08em;
            transform: rotate(45deg);
        } 



        /* .FlexGrid .Mask .Icon .MagnifyingGlass {
        
            font-size: 4em;
            display: inline-block;
            width: 0.4em;
            box-sizing: content-box;
            height: 0.4em;
            border: 0.1em solid #EDEEF1;
            position: relative;
            border-radius: 0.35em;
        
        }
        .FlexGrid .Mask .Icon .MagnifyingGlass:before {
        
            content: "";
            display: inline-block;
            position: absolute;
            right: -0.25em;
            bottom: -0.1em;
            border-width: 0;
            background: #EDEEF1;
            width: 0.35em;
            height: 0.08em;
            transform: rotate(45deg);
        } */
        


        .FlexGrid .Mask .Text {
                
            background-color:transparent;

            width:50px;
            height:auto;
            padding:0 0 0 0;
            margin:0 0 0 0;
                
            position:absolute;
            top:7px;
            left:-22px;
            z-index:11;
        
        
            font-family:Arial, Helvetica, sans-serif;
            font-size:18px;
            font-weight:600;
            color:white;
            text-align:center;
                            
            font-style:normal;
            
            
            user-select: none;
            -moz-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -o-user-select: none;
                
        
        }











        .FlexGrid .Cell .Container {

            background-color:white; 
            border:0;
            border-radius: 0;
            width: 100%;
            height: auto;
            padding: 0 0 15px 0;
            margin: 0 0 0 0;
            position: relative; 
            float:left; 
            

        }




            .FlexGrid .Cell .ThmbSizer {

                background-color:black;
                border:1px solid lightgray;
                position: relative;
                width: 100%;
                padding-top: 150%; 
                margin:0 0 0 0;
                overflow:hidden;


            }


            .FlexGrid .Cell .Thmb {

                background-color:#000;
                width:100%;
                height:auto;
                padding:0;
                overflow: hidden;

                position:absolute;
                top:0px;
                left:0px;
                z-index:1;

            }


                .FlexGrid .Cell .Thmb .Img {

                    flex-shrink: 0;
   
                    min-width: 100%;
                    min-height: 64%;

                    position:relative;
                    float:left;

                    margin:auto;
    
                    opacity: 1;
                    display: block;
                    width: 100%;
                    height: auto;
                    transition: .5s ease;
                    backface-visibility: hidden;
    
    
                }
    
    








                /****************************************

                ImageOnly 

                ****************************************/

                /*
                https://www.w3schools.com/howto/howto_css_image_overlay.asp
                */
    
                .FlexGrid .Cell .ImageOnly_Img {
    
                    flex-shrink: 0;
    
                    min-width: 100%;
                    min-height: 100%;
    
                    position:relative;
                    float:left;
    
                    margin:auto;
    
                    opacity: 1;
                    display: block;
                    width: 100%;
                    height: auto;
                    transition: .5s ease;
                    backface-visibility: hidden;
    
    
                }
                .FlexGrid .Cell:hover .ImageOnly_Img {
    
                    opacity: 0.3;
        
                }
    

               


                /****************************************

                ImageText 

                ****************************************/

                /*
                https://www.w3schools.com/howto/howto_css_image_overlay.asp
                */
    
                .FlexGrid .Cell .Container .ImageText_Img {
    
                    flex-shrink: 0;
    
                    min-width: 100%;
                    min-height: 64%;
    
                    position:relative;
                    float:left;
    
                    margin:auto;
    
                    opacity: 1;
                    display: block;
                    width: 100%;
                    height: auto;
                    transition: .5s ease;
                    backface-visibility: hidden;
    
    
                }
                .FlexGrid .Cell .Container:hover .ImageText_Img {
    
                    opacity: 0.3;
        
                }
    
        
               
        
            .FlexGrid .Cell .Heading {

                background-color:transparent;
                width:90%;
                height:auto;
                padding:15px 0 15px 0; 
                margin:0 0 0 5%;
                position:relative; 
                float:left;
         
                 
                font-family:Arial, Helvetica, sans-serif;
                font-size:22px;
                font-weight:bold;
                color:#454d5f;
                text-align:center;

            
            }
            @media (min-width: 1024px) {

                .FlexGrid .Cell .Heading {

                    font-size: 1.5em;

                }

            }


            .FlexGrid .Cell .Pgraph {

                background-color:transparent;
                width:90%;
                height:auto;
                padding:0 0 0 0; 
                margin:0 0 20px 5%;
                position:relative; 
                float:left;
        
                font-family:Arial, Helvetica, sans-serif;
                font-size:18px;
                font-weight:normal;
                color:#000;
                text-align:left;


            }

            @media (min-width: 1024px) {

                .FlexGrid .Cell .Pgraph {

                    font-size: 1rem;

                }

            }



         





/**********************************
FlexGridB

https://www.geeksforgeeks.org/how-to-set-flexbox-having-unequal-width-of-elements-using-css/

***********************************/

.FlexGridB{ 

    display: flex; 
    flex-wrap: wrap;
    min-height: 550px; 

} 
    .FlexGridB .Column{ 

        flex: 1; 
    } 
    .FlexGridB .Column:nth-of-type(odd){ 

        background:transparent;  
    } 
    .FlexGridB .Column:nth-of-type(even){ 

        background:transparent; 
    } 


    @media (max-width: 1050px) {


        .FlexGridB .Column{ 

            flex: 2; 
        } 

    }


    @media (max-width: 800px) {


        .FlexGridB .Column{ 

            flex: 3; 
        } 

    }
    @media (max-width: 500px) {

        .FlexGridB .Column{ 

            flex: 4; 
        } 


    }


        .FlexGridB .Cell {

            background-color:transparent;
            border:0;
            width:100%;
            height:auto;
            padding:0 0 0 0;
            margin:0 0 0 0;
        
            position: relative;
            float: left;
            overflow: visible;

            cursor:pointer;

        }




        .FlexGridB .Cell .Mask {

            background-color:transparent;
            width:100%;
            height:100%;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:absolute;
            top:0px;
            left:0px;
            z-index:2;

        }
        .FlexGridB .Cell .Mask .MaskBox {
            transition: .5s ease;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
        }
        .FlexGridB .Cell:hover .MaskBox {

            opacity: 1;

        }     
        .FlexGridB .Cell .Mask .MaskTxt {

            background-color: #4CAF50;
            color: white;
            font-size: 16px;
            padding: 16px 32px;

        }







        .FlexGridB .Cell .Container {

            background-color:white; 
            border: 1px solid lightgray;
            border-radius: 0;
            width: 100%;
            height: auto;
            padding: 0 0 15px 0;
            margin: 0 0 0 0;
            position: relative; 
            float:left; 
            

        }




            .FlexGridB .Cell .Thmb {

                background-color:#000;
                width:100%;
                padding:0;
                overflow: hidden;

            }


                .FlexGridB .Cell .Thmb .Img {

                    flex-shrink: 0;
   
                    min-width: 100%;
                    min-height: 64%;

                    position:relative;
                    float:left;

                    margin:auto;
    
                    opacity: 1;
                    display: block;
                    width: 100%;
                    height: auto;
                    transition: .5s ease;
                    backface-visibility: hidden;
    
    
                }
    
    








                /****************************************

                ImageOnly 

                ****************************************/

                /*
                https://www.w3schools.com/howto/howto_css_image_overlay.asp
                */
    
                .FlexGridB .Cell .ImageOnly_Img {
    
                    flex-shrink: 0;
    
                    min-width: 100%;
                    min-height: 64%;
    
                    position:relative;
                    float:left;
    
                    margin:auto;
    
                    opacity: 1;
                    display: block;
                    width: 100%;
                    height: auto;
                    transition: .5s ease;
                    backface-visibility: hidden;
    
    
                }
                .FlexGridB .Cell:hover .ImageOnly_Img {
    
                    opacity: 0.3;
        
                }
    

               


                /****************************************

                ImageText 

                ****************************************/

                /*
                https://www.w3schools.com/howto/howto_css_image_overlay.asp
                */
    
                .FlexGridB .Cell .Container .ImageText_Img {
    
                    flex-shrink: 0;
    
                    min-width: 100%;
                    min-height: 64%;
    
                    position:relative;
                    float:left;
    
                    margin:auto;
    
                    opacity: 1;
                    display: block;
                    width: 100%;
                    height: auto;
                    transition: .5s ease;
                    backface-visibility: hidden;
    
    
                }
                .FlexGridB .Cell .Container:hover .ImageText_Img {
    
                    opacity: 0.3;
        
                }
    
        
               







        
            .FlexGridB .Cell .Heading {

                background-color:transparent;
                width:90%;
                height:auto;
                padding:15px 0 15px 0; 
                margin:0 0 0 5%;
                position:relative; 
                float:left;
         
                 
                font-family:Arial, Helvetica, sans-serif;
                font-size:22px;
                font-weight:bold;
                color:#454d5f;
                text-align:center;

            
            }
            @media (min-width: 1024px) {

                .FlexGridB .Cell .Heading {

                    font-size: 1.5em;

                }

            }


            .FlexGridB .Cell .Pgraph {

                background-color:transparent;
                width:90%;
                height:auto;
                padding:0 0 0 0; 
                margin:0 0 20px 5%;
                position:relative; 
                float:left;
        
                font-family:Arial, Helvetica, sans-serif;
                font-size:18px;
                font-weight:normal;
                color:#000;
                text-align:left;


            }

            @media (min-width: 1024px) {

                .FlexGridB .Cell .Pgraph {

                    font-size: 1rem;

                }

            }



         





