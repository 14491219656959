

/***********************************
Rows

http://geniuscarrier.com/2-columns-layout-with-flexbox/

***********************************/

.Rows {

    background-color:transparent;
    width: 100%;
    height:auto;
    padding:0 0 0 0;
    margin:0 0 0 0;

    position:relative;
    float:left;


}
@media (max-width: 1366px) {

    .Rows {

        background-color:transparent;
        width: 100%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;

        position:relative;
        float:left;

    
    }

}
    

    .Rows .FullRow {

        background-color:transparent;
        width:100%;
        height:auto;
        margin:0 auto 0 auto;
        position:relative;
        float:left;
    

    }







    .Rows .IndentRow {

        background-color:transparent;
        width:70%;
        height:auto;
        min-height:150px;
        margin:0 0 0 15%;
        position:relative;
        float:left;

    


    }

    .Rows .IndentRowB {

        background-color:transparent;
        width:70%;
        height:auto;
        min-height:150px;
        margin:0 0 0 15%;
        position:relative;
        float:left;
        


    }

    @media (max-width: 1680px) {

        .Rows .IndentRow {

            width: 84%;
            margin-left:8%
      
    
        }
        .Rows .IndentRowB {

            width: 84%;
            margin-left:8%
   
    
        }


    }
    @media (max-width: 1280px) {

        .Rows .IndentRow {

            width: 96%;
            margin-left:2%
        
    
        }
        .Rows .IndentRowB {

            width: 90%;
            margin-left:5%
      
    
        }


    }







    .Rows .IndentRowCompact {

        background-color:transparent;
        width:850px;
        height:auto;
        margin:0 0 0 calc(50% - 425px);
        position:relative;
        float:left;


    }
    @media (max-width: 875px) {

        .Rows .IndentRowCompact {

            width: 96%;
            margin-left:2%
    
        }

    }



    .Rows .IndentRowWide {

        background-color:transparent;
        width:98%;
        height:auto;
        margin:0 auto 0 auto;
        position:relative;
        float:left;


    }
    @media (max-width: 800px) {

        .Rows .IndentRowWide {

            width: 96%;
            margin-left:2%
    
        }

    }





    .Rows .IndentRowCenter {

        background-color:transparent;
        width:700px;
        height:auto;
        margin:0 0 0 calc(50% - 325px);
        position:relative;
        float:left;

        display:flex;
        justify-content: center; 
        align-items: center;


    }


    @media (max-width: 875px) {

        .Rows .IndentRowCenter {

            width:calc(100% - 50px);
            margin-left:25px;
    
        }

    }




